import HarbourLightFont from './Harbour-Light.ttf';

export const HarbourLight = {
  fontFamily: 'Harbour-Light',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#fff',
  src: `
    local('Harbour-Light'),
    url(${HarbourLightFont}) format('truetype')
  `,
};
