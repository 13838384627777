import React, { useEffect, useState } from 'react';
import { Button, Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import Layout from '../../common/Layout';
import ConnectWalletButton from '../../common/Account/ConnectWalletButton';
import ChampionHero from './ChampionHero';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/all';
import { useHistory, useParams } from 'react-router';
import { useAxios } from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setChampionMetadata } from '../../redux/champions';
import { useTypedSelector } from '../../redux/store';
import { ChampionProfileParams } from '../../routes';
import clsx from 'clsx';
import { useAwakenedKingdomData, useAwakenedKingdomTokenURI } from '../../hooks/useAwakenedKingdom';
import { toFastURI } from '../../utils';
import HoloCard from '../../common/HoloCard';

const ChampionPage: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const history = useHistory();
  const params = useParams<ChampionProfileParams>();
  const id = parseInt(params.id);
  const axios = useAxios();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const uri = useAwakenedKingdomTokenURI(id);
  const metadata = useTypedSelector((state) => state.champions.data[id]) as any;
  const { totalSupply } = useAwakenedKingdomData(account);

  const hasPreviousDeck = id > 1;
  const hasNextDeck = id < totalSupply.toNumber();

  useEffect(() => {
    if (!uri) return;
    axios
      .get(uri)
      .then((r) => {
        dispatch(setChampionMetadata({ [id]: r.data }));
        setIsFetching(false);
      })
      .catch(() => {
        setError('This Champion slumbers.');
        setIsFetching(false);
      });
  }, [axios, dispatch, id, uri]);

  if (!account || error) {
    return (
      <Layout hideFooter>
        <Container maxWidth="lg" className={clsx(classes.root, { [classes.fullHeight]: true })}>
          <div className={classes.connectButtonContainer}>
            {error ? (
              <Typography variant="h6" color="primary">
                {error}
              </Typography>
            ) : (
              <ConnectWalletButton size="large" />
            )}
          </div>
        </Container>
      </Layout>
    );
  }

  if (isFetching) {
    return (
      <Layout withStrictNetworkConnection withNotifications hideFooter>
        <Container maxWidth="lg" className={classes.outerWrapper}>
          <div className={classes.fetchingContainer}>
            <Typography variant="h5" color="primary">
              Fetching...
            </Typography>
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout withStrictNetworkConnection withNotifications>
      <Container maxWidth="xl" className={classes.outerWrapper}>
        {metadata && !metadata.requiresGenesisSeed ? (
          <>
            <div className={classes.backToVaultButton}>
              <Button
                startIcon={<RiArrowLeftSLine size={20} />}
                onClick={() => history.push('/vault')}
                variant="outlined"
                color="secondary"
              >
                Back to Your Vault
              </Button>
            </div>

            <div className={classes.buttonsContainer}>
              <Button
                startIcon={<RiArrowLeftSLine size={20} />}
                onClick={() => history.push(`/champion/${id - 1}`)}
                disabled={!hasPreviousDeck}
                variant="outlined"
                color="secondary"
              >
                View Previous Deck
              </Button>

              <Button
                endIcon={<RiArrowRightSLine size={20} />}
                onClick={() => history.push(`/champion/${id + 1}`)}
                disabled={!hasNextDeck}
                variant="outlined"
                color="secondary"
              >
                View Next Deck
              </Button>
            </div>

            <div className={classes.wrapper}>
              <div className={classes.root}>
                <ChampionHero />
              </div>

              <List className={classes.championCardsContainer}>
                <ListItem className={classes.championCardItem}>
                  <div className={classes.card}>
                    <HoloCard card={toFastURI(metadata.ui.assets.deck.back)} />
                  </div>
                </ListItem>
                <ListItem className={classes.championCardItem}>
                  <div className={classes.card}>
                    <HoloCard withAnimation card={toFastURI(metadata.ui.assets.champion)} />
                  </div>
                </ListItem>
              </List>

              <Typography variant="h5">
                {metadata.name} - Edition # {id}
              </Typography>
              <Typography className={classes.description} variant="body1">
                {metadata.description}
              </Typography>

              <Typography className={classes.attributesTitle} variant="h6">
                Attributes
              </Typography>
              <List className={classes.attributes}>
                {metadata.attributes.map((attr: any) => (
                  <ListItem className={classes.attributeItem} key={`${attr.trait_type}-${attr.value}`}>
                    <Typography variant="subtitle2">{attr.trait_type}</Typography>
                    <Typography variant="body1">{attr.value}</Typography>
                  </ListItem>
                ))}
              </List>

              <Typography className={classes.attributesTitle} variant="h6">
                Deck
              </Typography>

              <Container maxWidth="xl">
                <List className={classes.cardContainer}>
                  <ListItem>
                    <div className={classes.card}>
                      <HoloCard
                        back={toFastURI(metadata.ui.assets.deck.back)}
                        card={toFastURI(metadata.ui.assets.deck.back)}
                      />
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className={classes.card}>
                      <HoloCard
                        back={toFastURI(metadata.ui.assets.deck.back)}
                        card={toFastURI(metadata.ui.assets.deck.adCard)}
                      />
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className={classes.card}>
                      <HoloCard
                        card={toFastURI(metadata.ui.assets.deck['black-Joker'])}
                        back={toFastURI(metadata.ui.assets.deck.back)}
                      />
                    </div>
                    <Typography className={classes.cardRarityScoreTitle} variant="subtitle2">
                      Rarity Score: {metadata.ui.stats['black-Joker'].rarityScore}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <div className={classes.card}>
                      <HoloCard
                        card={toFastURI(metadata.ui.assets.deck['red-Joker'])}
                        back={toFastURI(metadata.ui.assets.deck.back)}
                      />
                    </div>
                    <Typography className={classes.cardRarityScoreTitle} variant="subtitle2">
                      Rarity Score: {metadata.ui.stats['red-Joker'].rarityScore}
                    </Typography>
                  </ListItem>

                  {Object.keys(metadata.ui.assets.deck)
                    .reverse()
                    .map(
                      (cardName: any) =>
                        !['red-Joker', 'black-Joker', 'back', 'adCard'].includes(cardName) && (
                          <ListItem key={cardName}>
                            <div className={classes.card}>
                              <HoloCard
                                card={toFastURI(metadata.ui.assets.deck[cardName])}
                                back={toFastURI(metadata.ui.assets.deck.back)}
                              />
                            </div>

                            <Typography className={classes.cardRarityScoreTitle} variant="subtitle2">
                              Rarity Score: {metadata.ui.stats[cardName].rarityScore}
                            </Typography>
                          </ListItem>
                        ),
                    )}
                </List>
              </Container>
            </div>
          </>
        ) : (
          <Typography variant="body1" color="primary" />
        )}
      </Container>
    </Layout>
  );
};

export default ChampionPage;
