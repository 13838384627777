import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import HoloCard from '../../../common/HoloCard';
import { faqs } from '../../pg-mint/AwakenenedChampionMintPage/FrequentlyAskedQuestions';

const OurTeam: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <div>
          <span className={classes.stepTitleContainer}>
            <Typography variant="body1">06</Typography>
            <Typography variant="h6">Our Team</Typography>
          </span>
          <div className={classes.explanationContainer}>
            <Typography variant="body1">
              We have a dedicated team ready to bring this vision to life and look forward to growing a community around
              the PIPS.
            </Typography>
            <Typography variant="body1">
              <Link target="_blank" href="https://discord.com/invite/8Q6d3Hh6rh">
                Join our community
              </Link>{' '}
              as an early adopter to be rewarded with airdrops, access to a community controlled DAO and more surprises
              along the way.
            </Typography>
          </div>
          <List className={classes.list}>
            <ListItem>
              <div className={classes.holoCardContainer}>
                <HoloCard withAnimation card="assets/pipwolf.svg" back="/assets/awakened-back.svg" />
              </div>
              <Typography variant="h6">Pipwolf</Typography>
              <Typography variant="body2">
                Design
                <br />
                and Illustration
              </Typography>
            </ListItem>
            <ListItem>
              <div className={classes.holoCardContainer}>
                <HoloCard withAnimation card="assets/th7.svg" back="/assets/awakened-back.svg" />
              </div>
              <Typography variant="h6">
                double<em>_</em>o<em>_</em>theven
              </Typography>
              <Typography variant="body2">
                CTO
                <br />
                and Ideation
              </Typography>
            </ListItem>
            <ListItem>
              <div className={classes.holoCardContainer}>
                <HoloCard withAnimation card="assets/piphop.svg" back="/assets/awakened-back.svg" />
              </div>
              <Typography variant="h6">Piphop</Typography>
              <Typography variant="body2">
                Project Management
                <br />
                and Community
              </Typography>
            </ListItem>
            <ListItem>
              <div className={classes.holoCardContainer}>
                <HoloCard withAnimation card="assets/mfx.svg" back="/assets/awakened-back.svg" />
              </div>
              <Typography variant="h6">MFX</Typography>
              <Typography variant="body2">
                Partnerships
                <br />
                and Community
              </Typography>
            </ListItem>
          </List>
        </div>
        <div className={classes.faqContainer}>
          <Typography variant="h6">FAQ</Typography>
          {faqs.map(({ question, answer }) => (
            <Accordion elevation={0} key={question}>
              <AccordionSummary
                className={classes.accordionTitle}
                expandIcon={<img src="/assets/chevron.svg" alt="chevron" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionContent}>
                <Typography>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default OurTeam;
