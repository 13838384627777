import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import HoloCard from '../../../common/HoloCard';

const CardGame: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <Typography className={classes.stepNumber} variant="body1">
          03
        </Typography>
        <Typography className={classes.title} variant="h6">
          Card Colosseum
        </Typography>
        <div className={classes.explanationContainer}>
          <Typography variant="body1">
            Those who first mint a Champion Card will have the opportunity to mint an entire deck of cards (54 images!)
            in a single NFT. These could be used in a series of future card games as well as future PIP-specific gaming
            systems.
          </Typography>
        </div>
        <div className={classes.gameBoardContainer}>
          <ul className={classes.otherPokerHandsContainer}>
            <li>
              <img src="assets/awakened-back.png" alt="Awakened Card Back" />
              <img src="assets/awakened-back.png" alt="Awakened Card Back" />
            </li>
            <li>
              <img src="assets/awakened-back.png" alt="Awakened Card Back" />
              <img src="assets/awakened-back.png" alt="Awakened Card Back" />
            </li>
          </ul>

          <ul className={classes.centerCardsContainer}>
            <li>
              <div className={classes.flopHoloCardContainer}>
                <HoloCard card="assets/queen-diamonds.svg" back="/assets/awakened-back.svg" />
              </div>
            </li>
            <li>
              <div className={classes.flopHoloCardContainer}>
                <HoloCard card="assets/3h2.svg" back="/assets/awakened-back.svg" />
              </div>
            </li>
            <li>
              <div className={classes.flopHoloCardContainer}>
                <HoloCard card="assets/jack-diamonds.svg" back="/assets/awakened-back.svg" />
              </div>
            </li>
            <li className={classes.transparent}>
              <div className={classes.flopHoloCardContainer}>
                <img src="assets/awakened-card-placeholder.png" alt="Awakened Card Placeholder" />
              </div>
            </li>
            <li className={classes.transparent}>
              <div className={classes.flopHoloCardContainer}>
                <img src="assets/awakened-card-placeholder.png" alt="Awakened Card Placeholder" />
              </div>
            </li>
          </ul>

          <div className={classes.myHandContainer}>
            <div className={classes.myHand}>
              <div className={clsx(classes.holoCardContainer, { [classes.firstCard]: true })}>
                <HoloCard withAnimation card="assets/king-spades.svg" back="/assets/awakened-back.svg" />
              </div>
              <div className={clsx(classes.holoCardContainer, { [classes.secondCard]: true })}>
                <HoloCard withAnimation card="assets/ace-spades.svg" back="/assets/awakened-back.svg" />
              </div>
            </div>
            <Typography color="primary" variant="h5">
              Example: Hold&apos;Em with your unique deck
            </Typography>
          </div>
        </div>
        <div className={classes.myHandContainerMobile}>
          <div className={classes.myHand}>
            <div className={clsx(classes.holoCardContainer, { [classes.firstCard]: true })}>
              <HoloCard withAnimation card="assets/king-spades.svg" back="/assets/awakened-back.svg" />
            </div>
            <div className={clsx(classes.holoCardContainer, { [classes.secondCard]: true })}>
              <HoloCard withAnimation card="assets/ace-spades.svg" back="/assets/awakened-back.svg" />
            </div>
          </div>
          <Typography color="primary" variant="h5">
            Example: Hold&apos;Em with your unique deck
          </Typography>
        </div>
        <div className={classes.shadow} />
        <div className={classes.bottomCharacterContainer}>
          <div className={classes.impArmy}>
            <img src="assets/imp-purple.png" alt="Purple Imp" />
            <img src="assets/imp-grey.png" alt="Grey Imp" />
          </div>
          <div className={classes.skeletonArmy}>
            <img src="assets/king-red.png" alt="Red King" />
            <img src="assets/queen-orange.png" alt="Orange Queen" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CardGame;
