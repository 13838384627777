import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import ConnectToNetworkButton from './ConnectToNetworkButton';
import { useCurrentNetworkId } from '../../hooks/useCurrentNetworkId';
import { toChain } from '../../config';

const WrongNetworkMessage: React.FC = () => {
  const classes = useStyles();
  const currentNetworkId = useCurrentNetworkId();
  const { chainName } = toChain(currentNetworkId);

  return (
    <div className={classes.root}>
      <Typography variant="h5">Please connect your wallet to the {chainName} network.</Typography>
      <ConnectToNetworkButton />
    </div>
  );
};

export default WrongNetworkMessage;
