import * as React from 'react';
import { useEthers } from '@usedapp/core';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';

type Props = {
  size?: 'large';
  marginBottom?: 'large';
};

const ConnectWalletButton: React.FC<Props> = ({ size, marginBottom }) => {
  const { activateBrowserWallet } = useEthers();
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, {
        [classes.marginBottomLarge]: marginBottom === 'large',
        [classes.withPadding]: size === 'large',
      })}
      size={size}
      variant="contained"
      fullWidth
      color="primary"
      onClick={() => activateBrowserWallet()}
    >
      Begin by connecting your wallet
    </Button>
  );
};

export default ConnectWalletButton;
