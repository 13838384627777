import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useEthers } from '@usedapp/core';
import { useCurrentNetworkId } from '../../../hooks/useCurrentNetworkId';
import { toChain, toChainParams } from '../../../config';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: Colors.BackgroundDark,
    },
  }),
);

type Props = {
  size?: 'large' | 'medium';
};

const ConnectToNetworkButton: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  const { library } = useEthers();
  const currentNetworkId = useCurrentNetworkId();
  const params = useMemo(() => toChainParams(currentNetworkId), [currentNetworkId]);
  const { chainName } = toChain(currentNetworkId);

  const handleClick = () => {
    library &&
      library.provider &&
      library.provider.request &&
      library?.provider?.request({ method: 'wallet_addEthereumChain', params });
  };

  return (
    <Button className={classes.root} size={size || 'large'} color="primary" variant="contained" onClick={handleClick}>
      Connect to {chainName}
    </Button>
  );
};

export default ConnectToNetworkButton;
