import React from 'react';
import { useTheme } from '@material-ui/core';
import { Colors } from '../../theme/Colors';

type Props = {
  width?: number;
  className?: string;
  primary?: string;
  secondary?: string;
  stroke?: string;
};

const Banner: React.FC<Props> = ({ width, primary, secondary, stroke, className }) => {
  const theme = useTheme();
  const defaultPrimary = theme.palette.secondary.main;
  const defaultSecondary = theme.palette.primary.main;
  const defaultStroke = Colors.BackgroundLight;

  return (
    <svg className={className} width={width || 60} viewBox="0 0 63 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.9218 93.9686L31.5156 50.7655L1 93.9686V5.48436V1H31.5156H61.9218V5.48436V93.9686Z"
        fill={primary || defaultPrimary}
        stroke={stroke || defaultStroke}
        strokeWidth="1.09375"
        strokeMiterlimit="10"
      />
      <path
        d="M57.2968 1V78.7655L31.7031 42.3437L6 78.7655V1H57.2968Z"
        fill={secondary || defaultSecondary}
        stroke={stroke || defaultStroke}
        strokeWidth="1.09375"
        strokeMiterlimit="10"
      />
      <path
        d="M31.8906 34.0312L11 63.6717V1H52.7812V63.6717L31.8906 34.0312Z"
        fill={primary || defaultPrimary}
        stroke={stroke || defaultStroke}
        strokeWidth="1.09375"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Banner;
