import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
      '& h4': {
        marginBottom: theme.spacing(3),
      },
      '& button': {
        textTransform: 'none',
        fontFamily: 'Harbour-light',
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    noAccountContainer: {
      width: '100%',
      maxWidth: 600,
      textAlign: 'center',
    },
    balanceList: {
      margin: theme.spacing(0, 0, 6),
      padding: 0,
      width: '100%',
      maxWidth: 600,
      '& li': {
        margin: theme.spacing(0, 0, 1),
        padding: 0,
        listStyleType: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h5': {
          marginRight: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 0, 1),
          },
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
    },
    valueContainer: {
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& img': {
        marginLeft: theme.spacing(1),
      },
    },
    pastTransactions: {
      marginTop: theme.spacing(6),
      width: '100%',
      maxWidth: 600,
      '& h5': {
        marginBottom: theme.spacing(2),
      },
    },
    notificationList: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
      '& li': {
        textAlign: 'left',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        display: 'flex',
        borderTop: `solid 1px ${Colors.GrayThree}`,
        padding: theme.spacing(1, 0),
      },
    },
    blockData: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      '& a': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);
