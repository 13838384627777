import React from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import Layout from '../../common/Layout';
import ConnectWalletButton from '../../common/Account/ConnectWalletButton';
import { toAvailableAmountToRelease, useTreasuryData, useTreasuryReleaseFunc } from '../../hooks/useTreasury';
import { formatEther } from 'ethers/lib/utils';
import { isPendingOrMining } from '../../utils';

const TreasuryPage: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const releaseFunc = useTreasuryReleaseFunc();
  const data = useTreasuryData(account);
  const availableAmount = toAvailableAmountToRelease(data);
  const { balance, totalShares, shares, totalReleased, released } = data;

  const BUTTON_TEXT: { [k: string]: string } = {
    PendingSignature: 'Waiting for signature...',
    Mining: 'Mining...',
  };

  if (!account) {
    return (
      <Layout hideFooter>
        <Container maxWidth="lg" className={classes.root}>
          <div className={classes.noAccountContainer}>
            <Typography variant="h4" color="primary">
              Treasury
            </Typography>
            <ConnectWalletButton size="large" />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout withNotifications hideFooter>
      <Container maxWidth="lg" className={classes.root}>
        <Typography variant="h4" color="primary">
          Treasury
        </Typography>

        <ul className={classes.balanceList}>
          <li>
            <Typography variant="h5" color="primary">
              Balance
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="body1">{formatEther(balance)} MOVR</Typography>
            </div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Total withdrawn
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="body1">{formatEther(totalReleased)} MOVR</Typography>
            </div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              You have withdrawn
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="body1">{formatEther(released)} MOVR</Typography>
            </div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Your shares
            </Typography>
            <Typography variant="body1">
              {shares.toString()} / {totalShares.toString()}
            </Typography>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Your available funds
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="body1">{formatEther(availableAmount)} MOVR</Typography>
            </div>
          </li>
        </ul>

        <Button
          size="large"
          disableElevation
          disabled={availableAmount.eq(0) || isPendingOrMining(releaseFunc.state.status)}
          variant="contained"
          color="primary"
          onClick={() => releaseFunc.send(account)}
        >
          {BUTTON_TEXT[releaseFunc.state.status] || 'Withdraw'}
        </Button>

        {releaseFunc.state.errorMessage && (
          <Typography variant="body1" color="primary">
            {releaseFunc.state.errorMessage}
          </Typography>
        )}
      </Container>
    </Layout>
  );
};

export default TreasuryPage;
