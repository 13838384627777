import React from 'react';
import { AppBar, Button, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import Logo from '../../Logo';
import { useHistory } from 'react-router';
import Account from '../../Account';
import SideDrawer from './SideDrawer';
import { useEthers } from '@usedapp/core';
import { useCurrentNetworkId } from '../../../hooks/useCurrentNetworkId';
import useChainConnectionState from '../../../hooks/useChainConnectionState';

const Header: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentNetworkId = useCurrentNetworkId();
  const { isWrongNetwork } = useChainConnectionState(currentNetworkId);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { account, deactivate } = useEthers();

  return (
    <AppBar elevation={0} className={classes.root}>
      <Link className={classes.iconLink} onClick={() => history.push('/')}>
        <Logo />
      </Link>
      {isMobileView ? (
        <SideDrawer />
      ) : (
        <ul className={classes.list}>
          <li>
            <Button
              className={classes.primaryButton}
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => history.push('/mint')}
            >
              Mint
            </Button>
          </li>

          {account && (
            <>
              <li>
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => history.push('/vault')}
                >
                  My Vault
                </Button>
              </li>
            </>
          )}

          <li>
            <Account />
          </li>

          {account && !isWrongNetwork && (
            <li>
              <Button variant="contained" color="secondary" size="medium" onClick={deactivate}>
                Disconnect
              </Button>
            </li>
          )}
        </ul>
      )}
    </AppBar>
  );
};

export default Header;
