import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import Layout from '../../common/Layout';
import ConnectWalletButton from '../../common/Account/ConnectWalletButton';
import { useParams } from 'react-router';
import { useAwakenedKingdomCohortGenesisSeeds, useAwakenedKingdomData } from '../../hooks/useAwakenedKingdom';

type Params = {
  cohort?: string;
};

const GenesisSeedsPage: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const { paused } = useAwakenedKingdomData(account);
  const { cohort } = useParams<Params>();
  const genesisSeeds = useAwakenedKingdomCohortGenesisSeeds(cohort ? parseInt(cohort) : 0);

  if (!account) {
    return (
      <Layout hideFooter>
        <Container maxWidth="lg" className={classes.root}>
          <div className={classes.noAccountContainer}>
            <Typography variant="h4" color="primary">
              Genesis Seeds
            </Typography>
            <ConnectWalletButton size="large" />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout withNotifications hideFooter>
      <Container maxWidth="lg" className={classes.root}>
        <Typography variant="h4" color="primary">
          Genesis Seeds: {paused ? 'Paused' : 'Unpaused'}
        </Typography>
        <p>{JSON.stringify(genesisSeeds)}</p>
      </Container>
    </Layout>
  );
};

export default GenesisSeedsPage;
