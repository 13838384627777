import { useContractCalls, useEthers } from '@usedapp/core';
import { Contract, ContractInterface, ethers } from 'ethers';
import { Interface } from 'ethers/lib/utils';

import { useMemo } from 'react';
import { curry } from 'ramda';
import contractConfig, { ContractAddresses } from '../config';
import { useCurrentNetworkId } from './useCurrentNetworkId';

export function useContract<T extends Contract = Contract>(
  addressConfigKey: keyof ContractAddresses,
  ABI: ContractInterface,
): T | null {
  const { library } = useEthers();
  const currentNetworkChainId = useCurrentNetworkId();
  const address = contractConfig[currentNetworkChainId][addressConfigKey] || '';

  return useMemo(() => {
    const signer = library?.getSigner();
    if (!signer) return null;

    return new ethers.Contract(address, ABI, signer) as T;
  }, [ABI, address, library]);
}

export type ContractArgs = { method: string; args?: any[] };
export const toInterface = (abi: any): Interface => new Interface(abi);

export type SingleContractMultipleCallsFunc = (
  abi: Interface,
  contractAddressKey: keyof ContractAddresses,
  contractArgs: ContractArgs[],
) => (any[] | undefined)[];

export const singleContractMultipleCalls = curry<SingleContractMultipleCallsFunc>((abi, configKey, contractArgs) => {
  const currentNetworkChainId = useCurrentNetworkId();
  const address = contractConfig[currentNetworkChainId][configKey] || '';

  const calls = address
    ? contractArgs.map(({ method, args }) => ({
        abi,
        address,
        method,
        args: args || [],
      }))
    : [];

  return useContractCalls(calls);
});
