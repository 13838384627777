import React from 'react';
import { createPipsTheme } from './theme/createPIPsTheme';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePage from './containers/pg-home';
import MintPage from './containers/pg-mint';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { getDappConfig } from './config';
import { DAppProvider } from '@usedapp/core';
import TreasuryPage from './containers/pg-treasury';
import VaultPage from './containers/pg-vault';
import DappStatePage from './containers/pg-dapp-state-page';
import ChampionPage from './containers/pg-champion';
import GenesisSeedsPage from './containers/pg-dapp-genesis-seeds-page';

const ConnectedDappProvider: React.FC = ({ children }) => {
  return <DAppProvider config={getDappConfig()}>{children}</DAppProvider>;
};

function App() {
  const isDark = true;
  const theme = createPipsTheme(isDark);

  return (
    <ReduxProvider store={store}>
      <ConnectedDappProvider>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet" />
          </Helmet>
          <CssBaseline />
          <div className="App">
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/mint" component={MintPage} />
              <Route exact path="/vault" component={VaultPage} />
              <Route exact path="/champion/:id" component={ChampionPage} />
              <Route exact path="/treasury" component={TreasuryPage} />
              <Route exact path="/state" component={DappStatePage} />
              <Route exact path="/genesis-seeds/:cohort" component={GenesisSeedsPage} />
            </BrowserRouter>
          </div>
        </MuiThemeProvider>
      </ConnectedDappProvider>
    </ReduxProvider>
  );
}

export default App;
