import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
      '& h4': {
        marginBottom: theme.spacing(3),
      },
    },
    vaultContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    connectButtonContainer: {
      maxWidth: 400,
    },
    sectionTitle: {
      margin: theme.spacing(3, 0, 1),
      marginBottom: `0 !important`,
    },
    sectionSubtitle: {
      marginBottom: theme.spacing(3),
      color: Colors.GrayFive,
    },
  }),
);
