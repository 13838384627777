import React, { useMemo } from 'react';
import Banner from '../../../common/Banner';
import { useStyles } from './styles';
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { ChampionProfileParams } from '../../../routes';
import { useTypedSelector } from '../../../redux/store';
import { toChampionData } from '../toChampionData';
import { toSafeColor } from '../toSafeColor';
import { toFastURI } from '../../../utils';
import { useAwakenedKingdomOwnerOf } from '../../../hooks/useAwakenedKingdom';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';

const ChampionHero: React.FC = () => {
  const classes = useStyles();
  const params = useParams<ChampionProfileParams>();
  const id = parseInt(params.id);
  const metadata = useTypedSelector((state) => state.champions.data[id]) as any;
  const { account } = useEthers();
  const nftOwner = useAwakenedKingdomOwnerOf(id);
  const data = useMemo(() => toChampionData(metadata), [metadata]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <div className={classes.bannerContainer}>
          <Banner
            className={classes.banner}
            primary={toSafeColor(data.colors.primary)}
            secondary={toSafeColor(data.colors.secondary)}
            stroke={data.colors.stroke}
            width={60}
          />
        </div>
        <div className={clsx(classes.middleContainer, { [classes.hide]: isMobileView })}>
          <img className={classes.pfp} src={toFastURI(data.assets.pfp)} alt="Champion PFP" />
        </div>
        <div className={classes.bannerContainer}>
          <Banner
            className={classes.banner}
            primary={toSafeColor(data.colors.primary)}
            secondary={toSafeColor(data.colors.secondary)}
            stroke={data.colors.stroke}
            width={60}
          />
        </div>
      </div>

      <div className={clsx(classes.middleContainer, { [classes.hide]: !isMobileView })}>
        <img className={classes.pfp} src={toFastURI(data.assets.pfp)} alt="Champion PFP" />
      </div>

      <div className={classes.bottomContainer}>
        <Typography variant="h5">{data.formattedValue}</Typography>

        {nftOwner !== ethers.constants.AddressZero && nftOwner === account && (
          <Button
            download
            href={toFastURI(data.assets.pfp)}
            target="_blank"
            className={classes.button}
            variant="outlined"
            color="secondary"
            size="small"
          >
            Download PFP
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChampionHero;
