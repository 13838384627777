export const Colors = {
  Primary: '#BB6D2A',
  Secondary: '#6b5293',
  SecondaryDark: '#47257c',
  Info: '#E6A3EC',
  Background: '#0b121d',
  BackgroundDark: '#090F18',
  BackgroundLight: '#141D2D',
  Paragraph: '#929EAD',
  Border: '#2F3D54',
  BackgroundAccent: '#182336',
  Keepers: '#a37146',
  Hunters: '#518885',
  Mystics: '#709771',
  Awakened: '#c64940',
  GrayTwo: '#1e2633',
  GrayThreeAlt: '#273142',
  GrayThree: '#262e39',
  GrayFour: '#48505F',
  GrayFive: '#cdcdcd',
  Light: {
    Background: '#efefef',
  },
};
