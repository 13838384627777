import React from 'react';
import Layout from '../../common/Layout';
import Hero from './Hero';
import MintPassStep from './MintPassStep';
import OurTeam from './OurTeam';
import EnterTheMetaverse from './EnterTheMetaverse';
import CardGame from './CardGame';
import KingdomsOnChain from './KingdomsOnChain';
import Rarity from './Rarity';

const HomePage: React.FC = () => {
  return (
    <Layout>
      <Hero />
      <MintPassStep />
      <Rarity />
      <CardGame />
      <KingdomsOnChain />
      <EnterTheMetaverse />
      <OurTeam />
    </Layout>
  );
};

export default HomePage;
