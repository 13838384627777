import * as React from 'react';
import ConnectWalletButton from './ConnectWalletButton';
import { shortenIfAddress, useEthers, useEtherBalance } from '@usedapp/core';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { utils } from 'ethers';
import { useCurrentNetworkId } from '../../hooks/useCurrentNetworkId';
import useChainConnectionState from '../../hooks/useChainConnectionState';
import ConnectToNetworkButton from '../WrongNetworkMessage/ConnectToNetworkButton';

const Account: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const balance = useEtherBalance(account || '');
  const currentNetworkId = useCurrentNetworkId();
  const { isWrongNetwork } = useChainConnectionState(currentNetworkId);

  const formatAddress = () => shortenIfAddress(account);

  if (!account) return <ConnectWalletButton />;
  if (isWrongNetwork) return <ConnectToNetworkButton size="medium" />;

  return (
    <div className={classes.root}>
      <div className={classes.connectedContainer}>
        <div className={classes.balanceContainer}>
          <Typography variant="body2">
            {balance ? parseFloat(utils.formatEther(balance)).toPrecision(4) : '0.00'}
          </Typography>
          <Typography className={classes.currency} variant="body2">
            MOVR
          </Typography>
        </div>
        <Typography className={classes.addressContainer} variant="body2">
          {formatAddress()}
        </Typography>
      </div>
    </div>
  );
};

export default Account;
