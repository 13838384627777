import React from 'react';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import TimeLine from './TimeLine';

const KingdomsOnChain: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <div className={classes.leftContainer}>
          <div className={classes.leftInnerContainer}>
            <span className={classes.stepTitleContainer}>
              <Typography variant="body1">04</Typography>
              <Typography variant="h6">Cross Chain Wars</Typography>
            </span>
            <div className={classes.explanationContainer}>
              <Typography variant="body1" className={classes.paragraphEmphasis}>
                Over time, new kingdoms will be revealed across multiple chains.
              </Typography>
              <Typography variant="body1">
                Each kingdom plays an integral part in the story of Pips and introduces a new cast of Champions, game
                mechanics, and opportunities for the community to impact the universe. The result is chain agnostic
                decentralized gaming, where future chains are chosen based on community input.
              </Typography>
            </div>
            <div className={classes.timelineWrapper}>
              <Typography color="primary" variant="h5">
                Example Kingdom Reveal
              </Typography>
              <TimeLine />
              <div className={classes.timelineContinuedContainer}>
                <img src="assets/x-white.svg" alt="X" />
                <Typography variant="h6">Repeat For All Kingdoms</Typography>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.rightContainer}>
          <span className={classes.tabletStepTitleContainer}>
            <Typography variant="body1">04</Typography>
            <Typography variant="h6">Enter the Multichain</Typography>
          </span>
          <List className={classes.cardContainer}>
            <ListItem>
              <img src="assets/awakened.png" alt="Awakened card back" />
              <hgroup>
                <Typography variant="subtitle1">Chain 1 - Moonriver</Typography>
                <Typography className={classes.awakened} variant="h5">
                  The Awakened
                </Typography>
              </hgroup>
            </ListItem>
            <ListItem>
              <img src="assets/hunters.png" alt="Hunters card back" />
              <hgroup>
                <Typography variant="subtitle1">Chain 2 - TBD</Typography>
                <Typography className={classes.hunters} variant="h5">
                  The Hunters
                </Typography>
              </hgroup>
            </ListItem>
            <ListItem>
              <img src="assets/mystics.png" alt="Mystics card back" />
              <hgroup>
                <Typography variant="subtitle1">Chain 3 - TBD</Typography>
                <Typography className={classes.mystics} variant="h5">
                  The Mystics
                </Typography>
              </hgroup>
            </ListItem>
            <ListItem>
              <img src="assets/keepers.png" alt="Keepers card back" />
              <hgroup>
                <Typography variant="subtitle1">Chain 4 - TBD</Typography>
                <Typography className={classes.keepers} variant="h5">
                  The Keepers
                </Typography>
              </hgroup>
            </ListItem>
          </List>
          <div className={classes.pipsLogoContainer}>
            <img src="assets/hourglass.svg" alt="Hourglass" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default KingdomsOnChain;
