import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import { useStyles } from './styles';
import { Grid, List } from '@material-ui/core';
import HoloCard from '../../HoloCard';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { toFastURI } from '../../../utils';

type Props = {
  cards: any[];
  open: boolean;
};

const SmallScreenDeckReveal: React.FC<Props> = ({ cards, open }) => {
  const classes = useStyles();
  const history = useHistory();

  const transition = useTransition(open ? cards : [], {
    trail: 400 / cards.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  return (
    <Grid component={List} container spacing={4} justifyContent="center" alignItems="center" className={classes.root}>
      {transition((style, item) => (
        <Grid component="li" xs={12} sm={6} md={4} lg={3} item key={item.uri}>
          <animated.div
            onClick={() => !item.requiresGenesisSeed && history.push(`champion/${item.id}`)}
            className={clsx(classes.cardContainer, { [classes.link]: !item.requiresGenesisSeed })}
            style={style}
          >
            <HoloCard withAnimation={open} card={toFastURI(item?.image)} borderRadius="12px" />
          </animated.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default SmallScreenDeckReveal;
