import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      listStyleType: 'none',
      padding: 0,
      margin: theme.spacing(3, 'auto', 0),
      maxWidth: 420,
      '& li': {
        padding: 0,
        margin: theme.spacing(0, 0, 2),
      },
    },
  }),
);
