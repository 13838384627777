import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const animationDuration = '12s';
const color1 = '#fad';
const color2 = '#dca';

export const useStyles = (borderRadius?: string) =>
  makeStyles((_theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 2,
        touchAction: 'none',
        borderRadius: borderRadius ? borderRadius : '5% / 3.5%',
        boxShadow: `-7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`,
        willChange: 'transform, filter',
        backgroundColor: '#040712',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        transformOrigin: 'center',
        backgroundImage: 'url(assets/awakened-card-placeholder.png)',
        '&:hover': {
          boxShadow: `-20px -20px 30px -25px ${color1}, 20px 20px 30px -25px ${color2}, -7px -7px 10px -5px ${color1}, 7px 7px 10px -5px ${color2}, 0 0 13px 4px rgba(255, 255, 255, 0.3), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`,
          animation: 'none',
          transition: 'box-shadow 0.05s ease-out',
        },
        '&::before, &::after': {
          content: '" "',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          opacity: 0.5,
          mixBlendMode: 'color-dodge',
        },
        '&::before': {
          backgroundPosition: '50% 50%',
          backgroundSize: '300% 300%',
          backgroundImage: `linear-gradient(110deg, transparent 25%, ${color1} 48%, ${color2} 52%, transparent 75%)`,
          opacity: 0.5,
          filter: 'brightness(0.5) contrast(1)',
          zIndex: 1,
          animation: 'none',
          transition: 'none',
        },
        '&:hover::after': {
          filter: 'brightness(1) contrast(1)',
          opacity: 1,
          animation: 'none',
          transition: 'none',
        },
      },
      animated: {
        transition: 'none',
        animation: `$holoCard ${animationDuration} ease 0s infinite`,
        '&::before': {
          transition: 'none',
          animation: `$holoGradient ${animationDuration} ease 0s infinite`,
        },
      },
      background: {
        backgroundImage: 'url(assets/awakened-card-placeholder.png)',
      },
      '@keyframes holoGradient': {
        '100%': {
          opacity: 0.5,
          backgroundPosition: '50% 50%',
          filter: 'brightness(0.65) contrast(1)',
        },
        '9%': {
          backgroundPosition: '100% 100%',
          opacity: 1,
          filter: 'brightness(0.75) contrast(1.25)',
        },
        '25%': {
          backgroundPosition: '0% 0%',
          opacity: 0.88,
        },
        '55%': {
          backgroundPosition: '100% 100%',
          opacity: 1,
          filter: 'brightness(0.5) contrast(1)',
        },
        '85%': {
          backgroundPosition: '0% 0%',
          opacity: 1,
          filter: 'brightness(0.75) contrast(1.25)',
        },
      },

      '@keyframes holoCard': {
        '100%': {
          transform: 'rotateZ(0deg) rotateX(0deg) rotateY(0deg)',
        },
        '8%': {
          transform: 'rotateZ(0deg) rotateX(6deg) rotateY(-20deg)',
        },
        '25%': {
          transform: 'rotateZ(0deg) rotateX(-9deg) rotateY(32deg)',
        },
        '55%': {
          transform: 'rotateZ(3deg) rotateX(12deg) rotateY(20deg)',
        },
        '85%': {
          transform: 'rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg)',
        },
      },
      hover: {
        cursor: 'pointer',
      },
    }),
  );
