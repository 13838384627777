import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: Colors.GrayFive,
      textDecoration: 'underline',
      transition: 'color 200ms',
      '&:hover': {
        color: 'white',
      },
    },
    spacerTop: {
      marginTop: theme.spacing(1),
    },
  }),
);
