import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { RiMenu3Line } from 'react-icons/all';
import { useHistory } from 'react-router';
import Account from '../../../Account';
import { useEthers } from '@usedapp/core';

export const useStyles = makeStyles((_theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const SideDrawer: React.FC = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { account, deactivate } = useEthers();
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button className={classes.button} onClick={toggleDrawer}>
        <RiMenu3Line size={24} />
      </Button>
      <Drawer open={isOpen} anchor="right" onClose={toggleDrawer}>
        <div className={classes.list} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <List>
            <ListItem>
              <Account />
            </ListItem>
            {account && (
              <ListItem>
                <Button variant="contained" fullWidth color="secondary" size="medium" onClick={deactivate}>
                  Disconnect
                </Button>
              </ListItem>
            )}
            <ListItem>
              <Button variant="contained" fullWidth color="primary" size="medium" onClick={() => history.push('/mint')}>
                Mint
              </Button>
            </ListItem>
            {account && (
              <ListItem>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  size="medium"
                  onClick={() => history.push('/vault')}
                >
                  My Vault
                </Button>
              </ListItem>
            )}
            <ListItem>
              <Button variant="contained" fullWidth color="primary" size="medium" onClick={() => history.push('/')}>
                Home
              </Button>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
