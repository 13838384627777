import { createTheme, responsiveFontSizes, Theme } from '@material-ui/core';
import { Colors } from './Colors';
import { HarbourLight } from './fonts/fonts';
const fontFamily = ['Inter', 'sans-serif'].join(',');

export const createPipsTheme = (isDark: boolean): Theme =>
  responsiveFontSizes(
    createTheme({
      palette: {
        type: isDark ? 'dark' : 'light',
        background: {
          paper: isDark ? Colors.Background : Colors.Light.Background,
          default: isDark ? Colors.Background : Colors.Light.Background,
        },
        primary: {
          main: Colors.Primary,
        },
        secondary: {
          main: Colors.Secondary,
        },
        error: {
          main: Colors.Primary,
        },
        warning: {
          main: Colors.Keepers,
        },
        info: {
          main: Colors.Info,
        },
        success: {
          main: Colors.Mystics,
        },
      },
      typography: {
        fontFamily,
        h1: { ...HarbourLight },
        h2: { ...HarbourLight },
        h3: { ...HarbourLight },
        h4: { ...HarbourLight },
        h5: { ...HarbourLight },
        h6: { ...HarbourLight },
        subtitle1: { ...HarbourLight },
        subtitle2: { ...HarbourLight },
        allVariants: {
          color: isDark ? Colors.GrayFive : Colors.Background,
        },
        fontWeightBold: 900,
        fontWeightMedium: 700,
        fontWeightRegular: 400,
        button: {
          textTransform: 'none',
          fontFamily: 'Harbour-light',
          fontWeight: 400,
        },
      },
      props: {
        MuiButtonBase: {
          disableRipple: true,
        },
        MuiButtonGroup: {
          disableRipple: true,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [HarbourLight],
          },
        },
        MuiAppBar: {
          colorDefault: {
            backgroundColor: isDark ? Colors.Background : Colors.Light.Background,
          },
        },
        MuiAccordionSummary: {
          root: {
            padding: 0,
            '& p': {
              ...HarbourLight,
              padding: '16px 0',
            },
          },
        },
        MuiAccordionDetails: {
          root: {
            '& p': {
              ...HarbourLight,
              padding: '0 0 16px',
            },
          },
        },
      },
    }),
  );
