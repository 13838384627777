import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

export const faqs = [
  {
    question: 'How many traits are there?',
    answer:
      'There are over 500 traits across all cards in a deck - each of which have 4 levels of "rarity" (Ultra-Rare, Rare, Uncommon, Common) with different custom designs for every part. The permutations of these trait combinations will be added up to determine the overall rarity score.',
  },
  {
    question: 'What is the total supply?',
    answer:
      'The total supply of the Awakened kingdom is 5,000. Each of the decks will contain 54 unique cards totaling 270,000 cards, a PFP, and a card back.',
  },
  {
    question: 'Which wallet should I use?',
    answer: 'Metamask on the Moonriver network.',
  },
];

const FrequentlyAskedQuestions: React.FC = () => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      {faqs.map(({ question, answer }) => (
        <li key={question}>
          <Typography variant="subtitle2">{question}</Typography>
          <Typography variant="body2">{answer}</Typography>
        </li>
      ))}
    </ul>
  );
};

export default FrequentlyAskedQuestions;
