import React from 'react';
import { useStyles } from './styles';
import { Link } from '@material-ui/core';

type Props = {
  title: string;
  href?: string;
  target?: string;
};

const StyledLink: React.FC<Props> = ({ title, target, href }) => {
  const classes = useStyles();

  return (
    <Link className={classes.root} target={target} href={href}>
      {title}
    </Link>
  );
};

export default StyledLink;
