import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('sm')]: {},
    },
    tickMark: {
      height: 2,
      width: theme.spacing(6),
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        width: '10.5px',
      },
    },
    list: {
      borderLeft: `solid 2px ${theme.palette.primary.main}`,
      padding: theme.spacing(3, 0, 6),
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('sm')]: {
        borderLeft: 'none',
      },
      '& li': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        margin: theme.spacing(1.5, 0),
        '& h6': {
          margin: 0,
          padding: 0,
        },
      },
    },
  }),
);
