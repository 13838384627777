import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'center',
      width: '100%',
      margin: theme.spacing(2, 0),
      padding: 0,
      '& input[type=number]': {
        fontFamily: 'Harbour-Light',
        textAlign: 'center',
        fontSize: 20,
      },
      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& button': {
        height: 60,
        color: Colors.BackgroundDark,
      },
      '& button:first-child': {
        marginRight: theme.spacing(2),
      },
      '& button:last-child': {
        marginLeft: theme.spacing(2),
      },
    },
    input: {
      width: '100%',
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.light,
        },
      },
    },
  }),
);
