import React from 'react';
import { Container, Link, List, ListItem } from '@material-ui/core';
import { useStyles } from './styles';
import Logo from '../../Logo';
import { useHistory } from 'react-router';
import TimeTurner from '../../TimeTurner';
import clsx from 'clsx';

const Footer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <section className={classes.wrapper}>
      <Container component="footer" className={classes.root}>
        <Link className={clsx(classes.iconLink, { [classes.logo]: true })} onClick={() => history.push('/')}>
          <Logo color="primary" />
        </Link>

        <TimeTurner className={classes.timeTurner} color="primary" />

        <List className={classes.list}>
          <ListItem>
            <Link target="_blank" href="https://discord.com/invite/8Q6d3Hh6rh" className={classes.iconLink}>
              <img src="/assets/discord.svg" alt="Discord" />
            </Link>
            <Link target="_blank" href="https://twitter.com/pipcards?s=20" className={classes.iconLink}>
              <img src="/assets/twitter.svg" alt="Twitter" />
            </Link>
          </ListItem>
        </List>
      </Container>
    </section>
  );
};

export default Footer;
