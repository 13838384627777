import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useNotifications, Notification } from '@usedapp/core';
import Snackbar, { SnackSeverity } from '../Snackbar';
import { useStyles } from './styles';
import { useCurrentNetworkId } from '../../hooks/useCurrentNetworkId';
import useChainConnectionState from '../../hooks/useChainConnectionState';
import WrongNetworkMessage from '../WrongNetworkMessage';

const TRANSACTION_TITLES: { [key in Notification['type']]: { text: string; type: SnackSeverity } } = {
  transactionStarted: { text: 'Transaction Started...', type: 'info' },
  transactionSucceed: { text: 'Transaction Completed', type: 'success' },
  transactionFailed: { text: 'Transaction Failed :(', type: 'error' },
  walletConnected: { text: 'Wallet Connected', type: 'success' },
};

const Notifications: React.FC = () => {
  const { notifications } = useNotifications();

  return (
    <>
      {notifications.map((notification) => (
        <div key={notification.id}>
          <Snackbar
            {...{
              message: TRANSACTION_TITLES[notification.type].text,
              severity: TRANSACTION_TITLES[notification.type].type,
            }}
          />
        </div>
      ))}
    </>
  );
};

type Props = {
  hideFooter?: boolean;
  withNotifications?: boolean;
  withStrictNetworkConnection?: boolean;
};

const Layout: React.FC<Props> = ({ children, hideFooter, withNotifications, withStrictNetworkConnection }) => {
  const classes = useStyles();
  const currentNetworkId = useCurrentNetworkId();
  const { isWrongNetwork } = useChainConnectionState(currentNetworkId);

  useEffect(() => {
    try {
      // @ts-ignore
      window.analytics.page();
    } catch {}
  }, []);

  if (withStrictNetworkConnection && isWrongNetwork) {
    return (
      <>
        <Header />
        <div className={classes.wrongNetworkContainer}>
          <WrongNetworkMessage />
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      {withNotifications && <Notifications />}
      {children}
      {!hideFooter && <Footer />}
    </>
  );
};

export default Layout;
