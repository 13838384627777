import React, { useState } from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { useGesture } from 'react-use-gesture';
import { useSpring, animated as spring_animated, to } from '@react-spring/web';
export const CARD_RATIO = 250 / 350;

const DEFAULT_RECT: DOMRect = {
  toJSON(): any {},
  height: 0,
  width: 0,
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  x: 0,
  y: 0,
};

const calcX = (y: number, container: DOMRect) => {
  const { top, height } = container;
  return -(y - top - height / 2) / 20;
};

const calcY = (x: number, container: DOMRect) => {
  const { left, width } = container;
  return (x - left - width / 2) / 20;
};

const config = {
  hover: {
    scale: 1,
  },
  opacity: {
    min: 0.5,
    max: 1,
  },
  brightness: {
    min: 0.5,
    max: 1.5,
  },
  contrast: {
    min: 1,
    max: 1.33,
  },
};

const FIFTY_PERCENT = 50;

type ConfigItem = { min: number; max: number };

const calculateOffset = ([_, percentY]: [number, number], { min, max }: ConfigItem): number => {
  const difference = max - min;

  if (percentY <= FIFTY_PERCENT) {
    const multiplier = (FIFTY_PERCENT - percentY) / FIFTY_PERCENT;
    return min + multiplier * difference;
  }

  const distance = (percentY - FIFTY_PERCENT) / FIFTY_PERCENT;
  return min + distance * difference;
};

type Props = {
  card: string;
  back?: string;
  withAnimation?: boolean;
  withHoverZoom?: boolean;
  borderRadius?: string;
};

const HoloCard: React.FC<Props> = ({ card, withAnimation, borderRadius, withHoverZoom, back }) => {
  const [flipped, setFlipped] = useState(false);
  const visible = flipped ? back : card;

  const { root, animated, background } = useStyles(borderRadius)();
  const [isLoaded, setIsLoaded] = useState(false);
  const [position, setPosition] = useState<[number, number]>([50, 50]);
  const [animate, setAnimate] = useState(withAnimation);

  React.useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();
    document.addEventListener('gesturestart', preventDefault);
    document.addEventListener('gesturechange', preventDefault);

    return () => {
      document.removeEventListener('gesturestart', preventDefault);
      document.removeEventListener('gesturechange', preventDefault);
    };
  }, []);

  const domTarget = React.useRef<HTMLDivElement>(null);
  const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, api] = useSpring(() => ({
    rotateX: 0,
    rotateY: flipped ? 180 : 0,
    rotateZ: 0,
    scale: 1,
    zoom: 0,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  useGesture(
    {
      onMove: ({ xy: [px, py] }) => {
        const rect = domTarget?.current?.getBoundingClientRect() || DEFAULT_RECT;

        const lp = ((px - rect.left) / rect.width) * 100;
        const tp = ((py - rect.top) / rect.height) * 100;

        setPosition([lp, tp]);

        return api({
          rotateX: calcX(py, rect),
          rotateY: calcY(px, rect),
          scale: withHoverZoom ? 1.2 : config.hover.scale,
        });
      },
      onHover: ({ hovering }) => {
        animate && setAnimate(false);
        if (!hovering) {
          withAnimation && !animate && setAnimate(true);
          api({ rotateX: 0, rotateY: 0, scale: 1 });
        }
      },
    },
    { domTarget, eventOptions: { passive: false } },
  );

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: [
            `.${root}:hover::before {`,
            `background-position: ${position[0]}% ${position[1]}%;`,
            `opacity: ${calculateOffset(position, config.opacity)};`,
            'backgroundSize: 250% 250%;',
            `filter: brightness(${calculateOffset(position, config.brightness)}) contrast(${calculateOffset(
              position,
              config.contrast,
            )};`,
            '}',
          ].join(' '),
        }}
      />
      <img src={card} style={{ display: 'none' }} onLoad={() => setIsLoaded(true)} alt="hidden" />
      <spring_animated.div
        ref={domTarget}
        onClick={() => setFlipped(Boolean(back) && !flipped)}
        className={clsx(root, { [animated]: animate, background })}
        style={{
          backgroundImage: `url(${isLoaded ? visible : 'assets/awakened-card-placeholder.png'})`,
          x,
          y,
          scale: to([scale, zoom], (s, z) => s + z),
          rotateX,
          rotateY,
          rotateZ,
          transform: `perspective(600px)`,
          cursor: back ? 'pointer' : 'inherit',
        }}
      />
    </>
  );
};

export default HoloCard;
