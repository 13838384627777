import React, { useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { Button, FormControl, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import ConnectWalletButton from '../../../common/Account/ConnectWalletButton';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import PageTitle from './PageTitle';
import HoloCard from '../../../common/HoloCard';
import { hasError, isPendingOrMining } from '../../../utils';
import { useCurrentNetworkId } from '../../../hooks/useCurrentNetworkId';
import useChainConnectionState from '../../../hooks/useChainConnectionState';
import LineX from '../../../common/LineX';
import { useAwakenedKingdomData, useAwakenedKingdomMintFunc } from '../../../hooks/useAwakenedKingdom';
import NumberInput from '../../../common/NumberInput';
import { useHistory } from 'react-router';

const AwakenedKingdomMintPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isSmallView = useMediaQuery(theme.breakpoints.down('sm'));
  const mintFunc = useAwakenedKingdomMintFunc();
  const { account } = useEthers();
  const { totalSupply, maxCount, isInitialized, maxCountPerWallet, mintFee, accountBalance, paused } =
    useAwakenedKingdomData(account);
  const [selectedCount, setSelectedCount] = useState(1);
  const [formError, setFormError] = useState<string | null>(null);

  const isLoading = maxCount.eq(0);
  const isSuccess = mintFunc.state.status === 'Success';
  const isSoldOut = maxCount.eq(totalSupply) && !isLoading;
  const remainingCountAllowed = maxCountPerWallet.sub(accountBalance);
  const totalMintFee = mintFee.mul(selectedCount);
  const areFieldsDisabled = isPendingOrMining(mintFunc.state.status) || selectedCount < 1;
  const hasReachedMaximumMints = remainingCountAllowed.eq(0);
  const canPurchaseMore = !isSoldOut && !hasReachedMaximumMints;

  const currentNetworkId = useCurrentNetworkId();
  const { isDisconnected } = useChainConnectionState(currentNetworkId);

  const maxMintCountRemaining = useMemo(
    () => maxCountPerWallet.sub(accountBalance).toNumber(),
    [maxCountPerWallet, accountBalance],
  );

  const BUTTON_TEXT: { [k: string]: string } = {
    PendingSignature: 'Waiting for signature...',
    Mining: 'Minting...',
  };

  const handleSelectedCountChange = (count: number) => {
    mintFunc.resetState();
    setFormError(null);
    count >= 0 && setSelectedCount(count);
    if (count > maxMintCountRemaining) {
      setFormError(`You can only mint ${maxMintCountRemaining} more Kingdom${maxMintCountRemaining > 1 ? 's' : ''}`);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.holoCardContainer}>
          <HoloCard card="assets/awakened-back.svg" withAnimation />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.rightScrollContainer}>
          <div className={classes.rightInnerContainer}>
            {isDisconnected ? (
              <div className={classes.titleSection}>
                <LineX horizontal marginBottom="small" color="gray" />
                <Typography variant="h5">Welcome to the Tomb</Typography>
                <ConnectWalletButton marginBottom="large" size="large" />
              </div>
            ) : (
              <>
                <PageTitle
                  isSoldOut={isSoldOut}
                  isLoading={isLoading}
                  isPaused={paused || !isInitialized}
                  maxMintCountReached={hasReachedMaximumMints}
                  mintCount={accountBalance.toNumber()}
                  isSuccessMessage={isSuccess}
                  isNotWhitelisted={false}
                />

                {isInitialized && !paused && (
                  <>
                    {canPurchaseMore && !isSmallView && (
                      <form className={classes.form}>
                        <FormControl fullWidth>
                          <Typography variant="body1">How many decks would you like to mint?</Typography>
                          <Typography variant="body2">Max mint per account: {maxCountPerWallet.toString()}</Typography>
                          <NumberInput
                            onChange={(e) => {
                              const count = parseInt(e.target.value || '0');
                              handleSelectedCountChange(count);
                            }}
                            selectedCount={selectedCount || undefined}
                            maxCount={maxMintCountRemaining}
                            onIncrement={() => handleSelectedCountChange(selectedCount + 1)}
                            onDecrement={() => handleSelectedCountChange(selectedCount - 1)}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Button
                            className={classes.button}
                            fullWidth
                            size="large"
                            disableElevation
                            variant="contained"
                            disabled={areFieldsDisabled || selectedCount > maxMintCountRemaining}
                            color="primary"
                            onClick={() => mintFunc.send(selectedCount, { value: totalMintFee })}
                          >
                            {BUTTON_TEXT[mintFunc.state.status] ||
                            (selectedCount > 0 && selectedCount <= maxMintCountRemaining)
                              ? `Purchase ${selectedCount} full deck${
                                  selectedCount > 1 ? 's' : ''
                                } for ${ethers.utils.formatEther(totalMintFee)} MOVR`
                              : 'Purchase Deck'}
                          </Button>
                        </FormControl>

                        {isSuccess && (
                          <>
                            <Typography className={classes.resultMessage} variant="h5" color="primary">
                              Success!
                            </Typography>
                            <Link className={classes.link} onClick={() => history.push('/vault')}>
                              Go to your vault
                            </Link>
                          </>
                        )}

                        {formError && (
                          <Typography className={classes.resultMessage} variant="body1" color="primary">
                            {formError}
                          </Typography>
                        )}

                        {hasError(mintFunc.state.status) && mintFunc.state.errorMessage && (
                          <Typography className={classes.resultMessage} variant="body1" color="primary">
                            {mintFunc.state.errorMessage}
                          </Typography>
                        )}
                      </form>
                    )}

                    <div className={classes.mintExplanation}>
                      <Typography variant="body1">
                        Awakened Kingdom decks are post-generated based on randomization on-chain. As a result,{' '}
                        <b>cards will be revealed in your vault up to 24 hours after mint.</b>
                      </Typography>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <Typography className={classes.totalMinted} variant="subtitle2">
            Total Decks Minted: {totalSupply.toString()}/{maxCount.toString()}
          </Typography>
          <FrequentlyAskedQuestions />
        </div>
      </div>
    </div>
  );
};

export default AwakenedKingdomMintPage;
