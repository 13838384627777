import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';
import clsx from 'clsx';

type Props = {
  className?: string;
  horizontal?: boolean;
  marginBottom?: 'small';
  color?: 'gray';
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fill: theme.palette.primary.main,
    },
    gray: {
      fill: Colors.GrayFour,
    },
    marginBottomSmall: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const LineX: React.FC<Props> = ({ className, horizontal, color, marginBottom }) => {
  const classes = useStyles();

  if (horizontal) {
    return (
      <svg
        className={clsx(classes.root, {
          [classes.gray]: color === 'gray',
          [classes.marginBottomSmall]: marginBottom === 'small',
          [className || '']: className,
        })}
        xmlns="http://www.w3.org/2000/svg"
        width="122"
        height="13.08"
        viewBox="0 0 122 13.08"
      >
        <path d="M122 6v1H0V6z" />
        <path d="M67.54 12.37l-.71.71L54.46.71l.71-.71z" />
        <path d="M66.83 0l.71.71-12.37 12.37-.71-.71z" />
      </svg>
    );
  }

  return (
    <svg
      width="15"
      height="122"
      viewBox="0 0 15 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="7" width="1" height="122" fill="#BB6D2A" />
      <rect x="13.3336" y="54.4592" width="1" height="17.5" transform="rotate(45 13.3336 54.4592)" fill="#BB6D2A" />
      <rect x="0.959229" y="55.1663" width="1" height="17.5" transform="rotate(-45 0.959229 55.1663)" fill="#BB6D2A" />
    </svg>
  );
};

export default LineX;
