import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';

const CARD_RATIO = 250 / 350;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
    },
    root: {
      marginTop: '-1px',
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(10, 4, 0),
      background: Colors.BackgroundDark,
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `solid 8px ${theme.palette.primary.main}`,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 4, 0),
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        borderBottom: `solid 8px ${theme.palette.primary.main}`,
        padding: theme.spacing(2, 5, 0),
      },
    },
    stepNumber: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(3),
      lineHeight: 0.8,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    title: {
      margin: theme.spacing(0, 0, 2),
      padding: 0,
      zIndex: 2,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    explanationContainer: {
      maxWidth: '614px',
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', 8, 'auto'),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        margin: theme.spacing(0, 'auto', 8, 'auto'),
        maxWidth: 'unset',
      },
      '& p': {
        color: Colors.Paragraph,
      },
    },
    nftContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(0, 5),
      margin: theme.spacing(0, 0, 5),
      width: '100%',
      listStyleType: 'none',
      '& li': {
        width: '25%',
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& p': {
          color: Colors.Paragraph,
          margin: theme.spacing(1, 0, 2),
        },
        '& img': {
          width: '100%',
        },
        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    holoCardContainer: {
      width: 400 * CARD_RATIO,
      height: 400,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: 450 * CARD_RATIO,
        height: 450,
      },
      [theme.breakpoints.down('sm')]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
    },
    holoChipContainer: {
      width: 250,
      height: 250,
      marginBottom: theme.spacing(4),
    },
  }),
);
