export const toSafeColor = (color: string) => {
  switch (color) {
    case 'rgb(43,51,61)':
    case 'rgb(53,55,68)':
    case 'rgb(56,56,56)':
      return 'white';
    default:
      return color;
  }
};
