import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import HoloCard from '../../../common/HoloCard';

const Rarity: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <Typography className={classes.stepNumber} variant="body1">
          02
        </Typography>
        <Typography className={classes.title} variant="h6">
          Rarity System
        </Typography>
        <div className={classes.explanationContainer}>
          <Typography variant="body1">
            One of the unique factors about Pips is how we handle rarity. Many projects pre-generate artwork with
            predetermined rarity. The rarest ones are often given to influencers, founders, family & friends; with a
            diluted pool trickling down to the marketplace. Pips, however, will post-generate NFTs based on an element
            of true randomness, giving everyone a fair shot at capturing a rare Pip.
          </Typography>
        </div>
        <ul className={classes.nftContainer}>
          <li>
            <div className={classes.holoChipContainer}>
              <img src="assets/chip.png" alt="PFP" />
            </div>
            <Typography variant="body1">PFP</Typography>
          </li>
          <li>
            <div className={classes.holoCardContainer}>
              <HoloCard withAnimation card="assets/qs.svg" />
            </div>
            <Typography variant="body1">Champion Card</Typography>
          </li>
          <li>
            <div className={classes.holoCardContainer}>
              <HoloCard withAnimation card="assets/awakened-back.png" />
            </div>
            <Typography variant="body1">Champion Card Back</Typography>
          </li>
        </ul>

        <div className={classes.explanationContainer}>
          <Typography variant="body1">
            Each Pip NFT has a series of 10+ traits that determines its rarity score. Your Champion Card&apos;s rarity
            score is based on the aggregated difficulty of generating each trait. Some of these traits are unique to the
            card, and some are shared across your deck.
          </Typography>
        </div>
      </Container>
    </section>
  );
};

export default Rarity;
