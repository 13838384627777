import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 3, 0),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: 600,
      margin: '0 auto',
      textAlign: 'center',
      '& h5': {
        marginBottom: theme.spacing(0),
      },
      '& a': {
        marginTop: theme.spacing(1),
      },
    },
    bannerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
      '& p': {
        marginTop: theme.spacing(2),
      },
      '& h5': {
        marginBottom: 0,
      },
    },
    banner: {
      marginTop: '-3px',
    },
    middleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h5': {
        margin: theme.spacing(1, 0),
      },
      [theme.breakpoints.down('sm')]: {
        position: 'unset',
      },
    },
    hide: {
      display: 'none',
    },
    twitterBtnWrapper: {
      marginBottom: theme.spacing(3),
    },
    pfp: {
      width: 176,
      height: 176,
      borderRadius: 200 / 2,
      margin: theme.spacing(3, 0, 3),
      backgroundColor: Colors.GrayThree,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 3),
      },
    },
    button: {
      margin: theme.spacing(3, 0, 4),
      color: 'white',
      backgroundColor: Colors.BackgroundLight,
    },
    genesisSeed: {
      border: `solid 1px ${Colors.Border}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      marginTop: theme.spacing(2),
    },
    deckScoreTitle: {
      marginTop: 0,
    },
  }),
);
