import React from 'react';
import { Button, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import TimeTurner from '../../../common/TimeTurner';
import { useStyles } from './styles';
import LineX from '../../../common/LineX';

const Hero: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMediumView = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <div className={classes.innerContainer}>
          <img className={classes.cardBackLargeScreen} src="assets/card-backs.png" alt="Back of Playing Cards" />
          <img className={classes.cardFrontLargeScreen} src="assets/card-fronts.png" alt="Front of Playing Cards" />
          <div className={classes.contentContainer}>
            <figure className={classes.logoContainer}>
              <TimeTurner />
            </figure>
            <hgroup>
              <Typography className={classes.preTitle} color="primary" variant="h5">
                The PIP NFT collective
              </Typography>
              <Typography className={classes.title} variant="h1">
                Enter <strong>The</strong> Kingdom
              </Typography>
            </hgroup>
            <div className={classes.blurbContainer}>
              <LineX horizontal={isSmallerThanMediumView} className={classes.lineX} />
              <div className={classes.blurbInnerContainer}>
                <Typography variant="h6">Randomly generated NFT playing cards for on-chain gaming</Typography>
                <span>
                  <Button
                    disableElevation
                    target="_blank"
                    href="https://discord.com/invite/8Q6d3Hh6rh"
                    variant="contained"
                    color="primary"
                  >
                    Join our Discord
                  </Button>
                  <Button
                    disableElevation
                    target="_blank"
                    href="https://twitter.com/pipcards"
                    variant="contained"
                    color="secondary"
                  >
                    Follow us on Twitter
                  </Button>
                </span>
              </div>
            </div>

            <div className={classes.cardMediumScreenContainer}>
              <img className={classes.cardBackMediumScreen} src="assets/card-backs.png" alt="Back of Playing Cards" />
              <img
                className={classes.cardFrontMediumScreen}
                src="assets/card-fronts.png"
                alt="Front of Playing Cards"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
