import TreasuryArtifact from '../artifacts/contracts/Treasury.sol/Treasury.json';
import { singleContractMultipleCalls, toInterface, useContract } from './useContract';
import { Treasury } from '../typechain';
import contractConfig from '../config';
import { useTypedSelector } from '../redux/store';
import { safelyUnwrapBN, safelyUnwrapBNResponse, toSafeAccount } from '../utils';
import { useContractFunction, useEtherBalance } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { useMemo } from 'react';

export const useTreasury = () => useContract<Treasury>('treasury', TreasuryArtifact.abi);

const TreasuryInterface = toInterface(TreasuryArtifact.abi);

export const useTreasuryWithMultipleCalls = singleContractMultipleCalls(TreasuryInterface, 'treasury');

export const toAvailableAmountToRelease = ({
  balance,
  totalShares,
  shares,
  totalReleased,
  released,
}: Omit<TreasuryData, 'availableAmount'>): BigNumber => {
  return totalShares.gt(0)
    ? balance.add(totalReleased).mul(shares).div(totalShares).sub(released)
    : BigNumber.from('0');
};

export function useTreasuryContract(): Treasury {
  const { currentNetworkChainId } = useTypedSelector((state) => state.app);
  const address = contractConfig[currentNetworkChainId].treasury || '';

  return useMemo(() => {
    return new ethers.Contract(address, TreasuryArtifact.abi) as Treasury;
  }, [address]);
}

type TreasuryData = {
  balance: BigNumber;
  totalShares: BigNumber;
  shares: BigNumber;
  totalReleased: BigNumber;
  released: BigNumber;
};

export const useTreasuryData = (account: string | undefined | null): TreasuryData => {
  const { currentNetworkChainId } = useTypedSelector((state) => state.app);
  const contractAddress = contractConfig[currentNetworkChainId].treasury || '';
  const balance = useEtherBalance(contractAddress);

  const [totalShares, shares, totalReleased, released] = useTreasuryWithMultipleCalls([
    { method: 'totalShares' },
    { method: 'shares(address)', args: [toSafeAccount(account)] },
    { method: 'totalReleased()' },
    { method: 'released(address)', args: [toSafeAccount(account)] },
  ]);

  return {
    balance: safelyUnwrapBN(balance),
    released: safelyUnwrapBNResponse(released),
    shares: safelyUnwrapBNResponse(shares),
    totalReleased: safelyUnwrapBNResponse(totalReleased),
    totalShares: safelyUnwrapBNResponse(totalShares),
  };
};

export const useTreasuryReleaseFunc = () => {
  const contract = useTreasuryContract();
  return useContractFunction(contract, 'release(address)', { transactionName: 'release' });
};
