import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Metadata = any;

interface ChampionsState {
  data: {
    [id: number]: Metadata;
  };
}

const initialState: ChampionsState = {
  data: {},
};

export const championsSlice = createSlice({
  name: 'champions',
  initialState,
  reducers: {
    setChampionMetadata: (state, action: PayloadAction<{ [id: number]: Metadata }>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setChampionMetadata } = championsSlice.actions;

export default championsSlice.reducer;
