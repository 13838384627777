import React, { useCallback, useEffect } from 'react';
import { useChain, useSpringRef, useSprings, animated, useSpring, config } from '@react-spring/web';
import { useStyles } from './styles';
import { List } from '@material-ui/core';
import HoloCard from '../../HoloCard';
import { useHistory } from 'react-router';
import clsx from 'clsx';

type Props = {
  cards: any[];
  open: boolean;
};

const to = (itemCount: number, i: number, isOpen: boolean) => ({
  x: isOpen ? (i + 1) * 274 + 60 : 10,
  delay: isOpen ? (itemCount - i) * 400 + 900 : i * 100 + 200,
});
const from = (_i: number) => ({});

const LargeScreenDeckReveal: React.FC<Props> = ({ cards, open }) => {
  const classes = useStyles();
  const history = useHistory();

  const containerRef = useSpringRef();
  const containerProps = useSpring({
    ref: containerRef,
    from: {
      y: -600,
      width: '100%',
    },
    to: {
      y: open ? 0 : -300,
      transform: `perspective(600px) rotateZ(${open ? 0 : 15}deg)`,
    },
    position: 'absolute',
    config: { ...config.default, duration: 500 },
  });

  const cardsRef = useSpringRef();
  const [cardProps, api] = useSprings(cards.length, (i) => ({
    ref: cardsRef,
    ...to(cards.length, i, open),
    position: 'absolute',
    transform: 'perspective(600px)',
    y: 12,
    x: 8,
    width: 250,
    height: 350,
    from: from(i),
  }));

  const cardBoxRef = useSpringRef();
  const cardBoxProps = useSpring({
    ref: cardBoxRef,
    from: {
      x: 0,
      y: 0,
    },
    to: {
      y: open ? 450 : 0,
      transform: `perspective(600px) rotateZ(${open ? -15 : 0}deg)`,
    },
    position: 'absolute',
    config: { ...config.default, duration: 500 },
  });

  useChain(open ? [containerRef, cardBoxRef, cardsRef] : [cardsRef, cardBoxRef, containerRef], [
    0,
    open ? 0.9 : 0.6,
    open ? 2.3 : 0.8,
  ]);

  const triggerCardsCallback = useCallback(() => {
    api.start((i) => to(cards.length, i, open));
  }, [api, open, cards.length]);

  useEffect(() => triggerCardsCallback(), [triggerCardsCallback]);

  return (
    <div className={classes.root}>
      <animated.div style={containerProps}>
        <List>
          <li className={classes.cardBoxBottomLi}>
            <img src="assets/card-box-bottom.svg" className={classes.cardBox} alt="card box bottom" />
          </li>
          {cardProps.map(({ ...style }, index) => (
            <animated.li
              onClick={() => !cards[index].requiresGenesisSeed && history.push(`champion/${cards[index].id}`)}
              className={clsx({ [classes.link]: !cards[index].requiresGenesisSeed })}
              key={cards[index].image}
              style={style}
            >
              <HoloCard card={cards[index].image} borderRadius="12px" />
            </animated.li>
          ))}
          <animated.li style={cardBoxProps}>
            <HoloCard card="assets/mint-pass.svg" borderRadius="6px" />
          </animated.li>
        </List>
      </animated.div>
    </div>
  );
};

export default LargeScreenDeckReveal;
