import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CARD_RATIO } from '../../common/HoloCard';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      '& h5': {
        marginBottom: theme.spacing(3),
      },
    },
    fullHeight: {
      height: '100vh',
      justifyContent: 'center',
    },
    outerWrapper: {
      margin: theme.spacing(10, 'auto', 6),
      padding: theme.spacing(0, 3, 0),
      '& button': {
        marginBottom: theme.spacing(3),
        fontFamily: 'Harbour-Light',
        color: 'white',
        backgroundColor: Colors.BackgroundLight,
      },
    },
    fetchingContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      height: '80vh',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(0, 3, 6),
      border: `solid 1px ${Colors.Border}`,
      borderRadius: theme.spacing(2),
    },
    connectButtonContainer: {
      maxWidth: 400,
    },
    description: {
      maxWidth: 600,
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
    attributesTitle: {
      marginTop: theme.spacing(8),
    },
    attributes: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    attributeItem: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      border: `solid 1px ${Colors.Border}`,
      borderRadius: theme.spacing(2),
      margin: theme.spacing(1),
      width: '25%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
      },
    },
    championCardsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0, 4),
    },
    championCardItem: {
      display: 'flex',
      justifyItems: 'center',
      padding: theme.spacing(2),
      width: '50%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      },
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& div': {
        margin: '0 auto',
      },
      '& li': {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
          width: '50%',
        },
        [theme.breakpoints.down('xs')]: {
          alignItems: 'center',
          width: '100%',
        },
        marginBottom: theme.spacing(4),
      },
    },
    card: {
      width: 400 * CARD_RATIO,
      height: 400,
      [theme.breakpoints.down(1365)]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
      [theme.breakpoints.down(1150)]: {
        width: 250 * CARD_RATIO,
        height: 250,
      },
      [theme.breakpoints.down('sm')]: {
        width: 275 * CARD_RATIO,
        height: 275,
      },
      [theme.breakpoints.down('xs')]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
    },
    firstCardsGroup: {
      display: 'flex',
      justifyItems: 'center',
      width: '100%',
      justifySelf: 'center',
    },
    cardRarityScoreTitle: {
      marginTop: theme.spacing(3),
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '& button:first-child': {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& button:first-child': {
          marginRight: 0,
        },
      },
    },
    backToVaultButton: {
      [theme.breakpoints.down('sm')]: {
        '& button': {
          width: '100%',
        },
      },
    },
  }),
);
