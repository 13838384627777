import React from 'react';
import { useStyles } from './styles';
import { Button, TextField } from '@material-ui/core';
import { RiAddLine, RiSubtractFill } from 'react-icons/all';

type Props = {
  selectedCount: number | undefined;
  maxCount: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onIncrement: () => any;
  onDecrement: () => any;
};

const NumberInput: React.FC<Props> = ({ selectedCount, onChange, onIncrement, onDecrement, maxCount }) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Button
        disabled={!selectedCount || selectedCount <= 1}
        variant="contained"
        onClick={onDecrement}
        color="primary"
        aria-label="Subtract"
      >
        <RiSubtractFill size={42} />
      </Button>
      <TextField type="number" fullWidth variant="outlined" value={selectedCount} onChange={onChange} />
      <Button
        color="primary"
        disabled={(selectedCount || 0) >= maxCount}
        variant="contained"
        onClick={onIncrement}
        aria-label="Add"
      >
        <RiAddLine size={42} />
      </Button>
    </span>
  );
};

export default NumberInput;
