import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
      },
    },
    connectedContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.BackgroundLight,
      padding: theme.spacing(0.5, 1, 0.5, 3),
      borderRadius: theme.spacing(0.5),
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    balanceContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    currency: {
      marginLeft: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 12,
    },
    addressContainer: {
      padding: theme.spacing(0.5, 1),
      backgroundColor: Colors.BackgroundDark,
      borderRadius: theme.spacing(0.5),
    },
    disconnect: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0, 0),
        width: '100%',
      },
    },
  }),
);
