import { ChainId, Config, MoonbaseAlpha, Moonriver } from '@usedapp/core';
import {
  AwakenedMintPassAddress as LocalhostAwakenedMintPassAddress,
  AwakenedChampionAddress as LocalhostAwakenedChampionAddress,
  AwakenedWhitelistAddress as LocalhostAwakenedWhitelistAddress,
  MulticallContractAddress as LocalhostMulticallContractAddress,
  TreasuryAddress as LocalhostTreasuryAddress,
} from '../artifacts/contracts/addresses/localhostContractAddresses';

import { AwakenedKingdomAddress as MoonbaseAwakenedKindomAddress } from '../artifacts/contracts/addresses/moonbaseAwakenedKingdomAddress';
import { AwakenedKingdomAddress as MoonriverAwakenedKindomAddress } from '../artifacts/contracts/addresses/moonriverAwakenedKingdomAddress';
import { AwakenedChampionAddress as MoonbaseAwakenedChampionAddress } from '../artifacts/contracts/addresses/moonbaseChampionAddress';

import * as MOVRAddresses from '../artifacts/contracts/addresses/moonriverContractAddresses';

export { toChain, toChainParams } from './toChainParams';

export const chainIdForEnv = (): ChainId => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return ChainId.MoonbaseAlpha;
    case 'development':
      return ChainId.Localhost;
    default:
      return ChainId.Moonriver;
  }
};

export const getDappConfig = (): Config => ({
  readOnlyUrls: {
    [ChainId.Moonriver]: `https://moonriver.api.onfinality.io/public`,
    [ChainId.MoonbaseAlpha]: `https://moonbeam-alpha.api.onfinality.io/public`,
    [ChainId.Localhost]: 'http://localhost:8545',
    [ChainId.Hardhat]: 'http://localhost:8545',
  },
  multicallAddresses: {
    [ChainId.Localhost]: LocalhostMulticallContractAddress || '',
  },
});

export type ContractAddresses = {
  treasury?: string;
  multicall: string;
  awakenedMintPass?: string;
  awakenedChampion?: string;
  awakenedKingdom?: string;
  whitelist?: string;
};

const contractConfig: Record<number, ContractAddresses> = {
  [ChainId.Localhost]: {
    awakenedMintPass: LocalhostAwakenedMintPassAddress,
    awakenedChampion: LocalhostAwakenedChampionAddress,
    multicall: LocalhostMulticallContractAddress,
    treasury: LocalhostTreasuryAddress,
    whitelist: LocalhostAwakenedWhitelistAddress,
  },
  [ChainId.MoonbaseAlpha]: {
    awakenedMintPass: '',
    awakenedChampion: MoonbaseAwakenedChampionAddress,
    awakenedKingdom: MoonbaseAwakenedKindomAddress,
    multicall: MoonbaseAlpha.multicallAddress,
    treasury: '',
    whitelist: '',
  },
  [ChainId.Moonriver]: {
    awakenedMintPass: MOVRAddresses.AwakenedMintPassAddress,
    awakenedChampion: MOVRAddresses.AwakenedChampionAddress,
    awakenedKingdom: MoonriverAwakenedKindomAddress,
    multicall: Moonriver.multicallAddress,
    treasury: MOVRAddresses.TreasuryAddress,
    whitelist: MOVRAddresses.AwakenedWhitelistAddress,
  },
};

export default contractConfig;
