import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';
import { CARD_RATIO } from '../../../common/HoloCard';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
    },
    root: {
      marginTop: '-1px',
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(10, 4, 0),
      background: 'linear-gradient(360deg, rgba(0, 163, 255, 0.2) 0%, rgba(0, 209, 255, 0) 35.75%), #141D2D',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `solid 8px ${theme.palette.primary.main}`,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 4, 0),
      },
    },
    stepNumber: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(3),
      lineHeight: 0.8,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    title: {
      margin: theme.spacing(0, 0, 2),
      padding: 0,
      zIndex: 2,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    explanationContainer: {
      maxWidth: '614px',
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', 8, 'auto'),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        margin: theme.spacing(0, 'auto', 8, 'auto'),
        maxWidth: 'unset',
      },
      '& p': {
        color: Colors.Paragraph,
      },
    },
    gameBoardContainer: {
      zIndex: 2,
      height: '100%',
      width: '100%',
      minHeight: '400px',
      backgroundImage: 'url("assets/mint-frame.svg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(8, 3),
      marginBottom: theme.spacing(20),
      '& img': {
        marginBottom: theme.spacing(3),
        width: '100%',
      },
      '& h6': {
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '& ul': {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        '& li': {
          width: '17%',
          margin: theme.spacing(0, 1, 0, 0),
          padding: 0,
        },
        '& li:last-child': {
          marginRight: 0,
        },
      },
    },
    centerCardsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(4),
      },
      '& li': {
        width: '17%',
        margin: theme.spacing(0, 1, 0, 0),
        padding: 0,
      },
      '& li:last-child': {
        marginRight: 0,
      },
    },
    transparent: {
      opacity: 0.7,
    },
    otherPokerHandsContainer: {
      display: 'flex',
      justifyContent: 'space-around !important',
      margin: '0 0 64px !important',
      width: '100%',
      padding: 0,
      listStyleType: 'none',
      [theme.breakpoints.down('md')]: {
        margin: '0 0 32px !important',
      },
      '& li': {
        width: '25% !important',
        margin: 0,
        padding: 0,
        '& img': {
          width: '50%',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
        '& img:first-child': {
          transform: 'rotate(-12deg)',
        },
        '& img:last-child': {
          transform: 'rotate(12deg)',
          marginLeft: theme.spacing(-3),
        },
      },
    },
    myHandContainer: {
      display: 'flex',
      justifyContent: 'space-around !important',
      margin: theme.spacing(6, 0, 0),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
      },
      width: '100%',
      '& h5': {
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
          alignSelf: 'flex-start',
          fontSize: '18px',
          maxWidth: 208,
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: 'unset',
        },
      },
    },
    myHandContainerMobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(6, 0),
        width: '100%',
        '& h5': {
          alignSelf: 'flex-start',
          fontSize: '18px',
        },
        '& img': {
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
      },
    },
    myHand: {
      width: '50%',
      position: 'relative',
      marginBottom: '-15%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: '-5%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(6),
      },
      '& img:first-child': {
        transform: 'rotate(-12deg)',
      },
      '& img:last-child': {
        transform: 'rotate(12deg)',
        marginLeft: theme.spacing(-3),
      },
    },
    firstCard: {
      transform: 'rotate(-12deg)',
    },
    secondCard: {
      transform: 'rotate(12deg)',
      marginLeft: theme.spacing(-3),
    },
    shadow: {
      filter: 'blur(94px)',
      background: 'black',
      width: '80%',
      height: '200px',
      margin: '-250px auto 0',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        height: '160px',
        marginTop: '-120px auto 0',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    battleExplanationContainer: {
      margin: theme.spacing(8, 0, 3),
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(3),
      width: '100%',
      backgroundColor: Colors.BackgroundLight,
      borderRadius: '3px',
      '& h4': {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
      },
      '& p': {
        borderRadius: '3px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        padding: theme.spacing(3, 0, 0),
        backgroundColor: 'unset',
        border: 'none',
      },
    },
    paragraphContainer: {
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(3),
      backgroundColor: Colors.BackgroundDark,
      [theme.breakpoints.down('sm')]: {
        borderRadius: '3px',
      },
    },
    paragraphEmphasis: {
      fontSize: '22px',
      marginBottom: theme.spacing(3),
    },
    bottomCharacterContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      marginTop: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },
    impArmy: {
      overflow: 'hidden',
      '& img': {
        width: '50%',
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
          width: '90%',
        },
      },
      '& img:nth-child(2)': {
        zIndex: 1,
        transform: 'scale(0.8) translate(0, 31px)',
        left: '-30%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          transform: 'scale(0.8) translate(0, 23px)',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    skeletonArmy: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      '& img': {
        width: '50%',
      },
      '& img:nth-child(2)': {
        zIndex: 2,
        transform: 'scale(1.1) translate(0, -12px)',
        [theme.breakpoints.down('md')]: {
          transform: 'scale(1.1) translate(0, -9px)',
        },
        [theme.breakpoints.down('sm')]: {
          width: '90%',
          transform: 'scale(1) translate(0, 0)',
        },
      },
      '& img:nth-child(1)': {
        zIndex: 1,
        transform: 'scale(0.9) translate(0, 14px)',
        right: '-20%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          transform: 'scale(0.9) translate(0, 9px)',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    holoCardContainer: {
      width: 300 * CARD_RATIO,
      height: 300,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: 280 * CARD_RATIO,
        height: 280,
      },
      [theme.breakpoints.down('sm')]: {
        width: 375 * CARD_RATIO,
        height: 375,
      },
      [theme.breakpoints.down('xs')]: {
        width: 230 * CARD_RATIO,
        height: 230,
      },
    },
    flopHoloCardContainer: {
      width: 250 * CARD_RATIO,
      height: 250,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down(1240)]: {
        width: 225 * CARD_RATIO,
        height: 225,
      },
      [theme.breakpoints.down(1130)]: {
        width: 200 * CARD_RATIO,
        height: 200,
      },
      [theme.breakpoints.down(1080)]: {
        width: 180 * CARD_RATIO,
        height: 180,
      },
    },
  }),
);
