import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      margin: 0,
      padding: 0,
      '& li': {
        width: 270,
        height: 378,
      },
    },
    cardBox: {
      width: 270,
      height: 378,
      borderRadius: theme.spacing(1),
      boxShadow: `-7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`,
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cardBoxBottomLi: {
      transform: 'none',
      position: 'absolute',
    },
  }),
);
