import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';
import { CARD_RATIO } from '../../../common/HoloCard';

const MAX_WIDTH = 500;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-1px',
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(0, 3, 6),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: theme.spacing(3, 3, 6),
      },
    },
    suitContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: theme.spacing(3, 0),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      '& img': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    spacerBottom: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        marginBottom: 'unset',
      },
    },
    mobileSuitContainer: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
    },
    stepNumber: {
      padding: theme.spacing(0),
      lineHeight: 0.8,
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.only('md')]: {
        position: 'relative',
        marginLeft: -475,
      },
    },
    title: {
      margin: theme.spacing(6, 'auto'),
      padding: 0,
      zIndex: 2,
      [theme.breakpoints.only('md')]: {
        maxWidth: MAX_WIDTH,
        margin: theme.spacing(3, 'auto'),
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: MAX_WIDTH,
        textAlign: 'center',
      },
      '& strong': {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '54px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '40px',
        },
      },
    },
    barSpacer: {
      margin: theme.spacing(0, 'auto'),
      width: '1px',
      height: '52px',
      backgroundColor: 'white',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    explanationContainer: {
      textAlign: 'center',
      maxWidth: MAX_WIDTH,
      margin: theme.spacing(6, 'auto'),
      zIndex: 2,
      '& p:first-child': {
        fontSize: '22px',
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
      },
      '& p': {
        color: Colors.Paragraph,
      },
    },
    threeHearts: {
      width: '20%',
      marginTop: 200,
      position: 'absolute',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('md')]: {
        left: '-6%',
        marginTop: 110,
        width: '22%',
        transform: 'rotate(67deg)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    huntersBack: {
      marginTop: 350,
      left: '13%',
      width: '15%',
      position: 'absolute',
      transform: 'rotate(22.77deg)',
      [theme.breakpoints.down('md')]: {
        left: '-7%',
        marginTop: 277,
        width: '18%',
        transform: 'rotate(38.77deg)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    keepersBack: {
      marginTop: 350,
      right: '13%',
      width: '15%',
      position: 'absolute',
      transform: 'rotate(131.1deg)',
      [theme.breakpoints.down('md')]: {
        marginTop: 275,
        right: '7%',
        width: '17%',
        transform: 'rotate(128.1deg)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    kingImp: {
      marginTop: 80,
      right: '13%',
      position: 'absolute',
      transform: 'rotate(-24.1deg)',
      [theme.breakpoints.down(1550)]: {
        right: '14%',
      },
      [theme.breakpoints.down(1400)]: {
        right: '11%',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 91,
        right: '9%',
        width: '26%',
      },
      [theme.breakpoints.down(1150)]: {
        right: '5%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    holoCardContainer: {
      width: 400 * CARD_RATIO,
      height: 400,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down(1550)]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
      [theme.breakpoints.down(1400)]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
      [theme.breakpoints.down('md')]: {
        width: 325 * CARD_RATIO,
        height: 325,
      },
      [theme.breakpoints.down(1150)]: {
        width: 300 * CARD_RATIO,
        height: 300,
      },
      [theme.breakpoints.down(1000)]: {
        width: 250 * CARD_RATIO,
        height: 250,
      },
    },
  }),
);
