import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 0,
      padding: 0,
      '& li': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cardContainer: {
      width: 250,
      height: 350,
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);
