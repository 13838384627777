import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(0, 7, 7),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 4, 7),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 1),
      },
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      backgroundColor: Colors.BackgroundDark,
      border: `solid 1px ${Colors.Border}`,
      marginTop: '-1px',
      padding: theme.spacing(4, 7),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 6),
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: theme.spacing(4),
      },
    },
    logo: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    iconLink: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    timeTurner: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    list: {
      padding: 0,
      margin: 0,
      '& li': {
        padding: 0,
      },
      '& button, & a': {
        marginRight: theme.spacing(3),
        textTransform: 'unset',
        fontFamily: 'Harbour-Light',
        fontWeight: 400,
      },
      '& button:last-child, & a:last-child': {
        marginRight: 0,
      },
    },
  }),
);
