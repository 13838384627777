import axios, { AxiosInstance } from 'axios';
import { useMemo } from 'react';

export const useAxios = (): AxiosInstance => {
  return useMemo(
    () =>
      axios.create({
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),
    [],
  );
};
