import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const EVENTS = [
  'Awakened Mint Pass Release',
  'Awakened Champion + PFPs Minted',
  'Awakened Decks Minted',
  'Community Votes on 2nd Chain (Hunters)',
  'Hunters Mint Pass Released',
  'Hunters Champion + PFPs Minted',
  'Hunters Decks Minted',
];

const TimeLine: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {EVENTS.map((event) => (
          <ListItem key={event}>
            <div className={classes.tickMark} />
            <Typography color="primary" variant="h6">
              {event}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default TimeLine;
