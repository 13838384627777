import React from 'react';
import { Container, ListItemText, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import HoloCard from '../../../common/HoloCard';

const MintPassStep: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container className={classes.root}>
        <div className={classes.leftContainer}>
          <div className={classes.fourSuits}>
            <img src="assets/spade.svg" alt="Spade" />
            <img src="assets/heart.svg" alt="Heart" />
            <img src="assets/diamond.svg" alt="Diamond" />
            <img src="assets/club.svg" alt="Club" />
          </div>
          <div>
            <span className={classes.stepTitleContainer}>
              <Typography variant="body1">01</Typography>
              <Typography variant="h6">What are PIPS?</Typography>
            </span>
            <div className={classes.explanationContainer}>
              <Typography variant="body1" className={classes.paragraphEmphasis}>
                PIPS is a first-of-its-kind NFT generative playing card project that will enable NFT holders to generate
                an entire deck of custom cards to be used cross-chain.
              </Typography>
              <Typography variant="h6">Limited Edition Mint Passes</Typography>
              <Typography variant="body1">
                To launch the collective, we&apos;re releasing a limited number of Awakened Mint Passes. Holders of the
                Mint Pass are entitled to mint a single Champion PIP that includes:
              </Typography>

              <ul className={classes.list}>
                <li>
                  <ListItemText>1 Unique Champion Court Card (PIP)</ListItemText>
                </li>
                <li>
                  <ListItemText>1 Unique Awakened Card Back</ListItemText>
                </li>
                <li>
                  <ListItemText>1 Custom PFP Chip</ListItemText>
                </li>
              </ul>

              <Typography variant="body1">
                To ensure fairness, we are creating a transparent and trustless system by generating a random number
                known as the &quot;Genesis Seed&quot; — or your PIP&apos;s DNA — that will determine the rarity of every
                single card in your NFT deck.
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.rightContainer}>
          <div className={classes.shadow} />
          <div className={classes.mintPassContainer}>
            <div className={classes.holoCardContainer}>
              <HoloCard withAnimation card="assets/awakened-back.svg" />
            </div>
            <Typography color="primary" variant="h6">
              1 of 5000
            </Typography>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MintPassStep;
