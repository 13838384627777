import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import SmallScreenDeckReveal from './SmallScreenDeckReveal';
import LargeScreenDeckReveal from './LargeScreenDeckReveal';

type Props = {
  cards: any[];
  open: boolean;
  withGrid?: boolean;
};

const DeckReveal: React.FC<Props> = ({ cards, open, withGrid }) => {
  const theme = useTheme();
  const isSmallView = useMediaQuery(theme.breakpoints.down('md'));
  const hasMoreThanThree = cards.length > 3;
  const showSmallView = hasMoreThanThree || isSmallView || withGrid;

  return showSmallView ? (
    <SmallScreenDeckReveal cards={cards} open={open} />
  ) : (
    <LargeScreenDeckReveal cards={cards} open={open} />
  );
};

export default DeckReveal;
