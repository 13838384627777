import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';
import { CARD_RATIO } from '../../../common/HoloCard';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
      marginTop: -58,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        padding: 0,
      },
    },
    root: {
      marginTop: '-8px',
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(10, 5, 6, 0),
      background: Colors.BackgroundLight,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: theme.spacing(8, 2, 2),
      },
    },
    leftContainer: {
      paddingRight: theme.spacing(4),
      width: '40%',
      '& h6': {
        marginBottom: theme.spacing(2),
        fontSize: '18px',
      },
      '& p': {
        marginBottom: theme.spacing(2),
        color: Colors.Paragraph,
      },
      [theme.breakpoints.down('md')]: {
        border: `solid 1px ${Colors.Border}`,
        borderRadius: '3px',
        width: '100%',
        padding: theme.spacing(3, 0),
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
    },
    stepTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3),
      },
      '& h6': {
        margin: 0,
        lineHeight: 0.8,
      },
      '& p': {
        lineHeight: 0.8,
        paddingLeft: theme.spacing(1),
        margin: theme.spacing(0, 6, 0, 0),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        borderLeft: `solid 4.5px ${theme.palette.primary.main}`,
        [theme.breakpoints.down('md')]: {
          borderTop: `solid 4.5px ${theme.palette.primary.main}`,
          borderLeft: 'none',
          padding: theme.spacing(1, 0, 5),
        },
        [theme.breakpoints.down('sm')]: {
          border: 'none',
        },
      },
    },
    fourSuits: {
      display: 'none',
      [theme.breakpoints.only('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flexStart',
        alignItems: 'center',
        padding: theme.spacing(7.5, 8, 0),
        '& img': {
          width: theme.spacing(3.5),
          marginBottom: theme.spacing(2),
        },
        '& img:last-child': {
          margin: 'none',
        },
      },
    },
    explanationContainer: {
      paddingLeft: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 18, 0, 0),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3),
      },
    },
    paragraphEmphasis: {
      fontSize: '22px',
    },
    list: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      listStyleType: 'disc !important',
      '& li': {
        padding: 0,
        listStyleType: 'disc',
        '& span': {
          color: Colors.Paragraph,
          fontSize: '16px',
        },
      },
    },
    rightContainer: {
      width: '60%',
      paddingTop: theme.spacing(14.5),
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.down('md')]: {
        paddingTop: '37%',
        width: '100%',
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '40%',
        width: '100%',
        marginBottom: theme.spacing(4),
      },
    },
    holoCardContainer: {
      width: 400 * CARD_RATIO,
      height: 400,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: 450 * CARD_RATIO,
        height: 450,
      },
      [theme.breakpoints.down('sm')]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
    },
    mintPassContainer: {
      zIndex: 2,
      height: '100%',
      minHeight: '400px',
      backgroundImage: 'url("assets/mint-frame.svg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'url("assets/mint-frame-v.svg")',
      },
      '& img': {
        marginBottom: theme.spacing(3),
        width: '40%',
        [theme.breakpoints.down('md')]: {
          width: '50%',
          padding: theme.spacing(2, 3, 0),
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: theme.spacing(2, 2, 0),
        },
      },
      '& h6': {
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: theme.spacing(4),
      },
    },
    shadow: {
      filter: 'blur(94px)',
      background: 'black',
      width: '80%',
      height: '200px',
      margin: '-180px auto 0',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        height: '160px',
        marginTop: '-120px auto 0',
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        height: '150px',
        marginTop: '-120px auto 0',
        filter: 'blur(46px)',
      },
    },
  }),
);
