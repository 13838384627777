import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(/assets/top-bar-bg.png) !important`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(1, 7),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2),
      },
    },
    iconLink: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    title: {
      color: Colors.Info,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    list: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      padding: 0,
      margin: 0,
      listStyleType: 'none',
      '& li': {
        padding: 0,
        margin: theme.spacing(0, 1, 0, 0),
        justifyContent: 'flex-end',
      },
      '& button, & a': {
        textTransform: 'unset',
        fontFamily: 'Harbour-Light',
        fontWeight: 400,
      },
      '& li:last-child': {
        marginRight: 0,
      },
    },
    primaryButton: {
      color: Colors.BackgroundDark,
    },
  }),
);
