import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import HoloCard from '../../../common/HoloCard';

const EnterTheMetaverse: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <img className={classes.huntersBack} src="assets/hunters.png" alt="Back of Hunters Card" />
      <div className={clsx(classes.holoCardContainer, { [classes.threeHearts]: true })}>
        <HoloCard withAnimation card="assets/3h2.svg" back="/assets/awakened-back.svg" />
      </div>
      <img className={classes.keepersBack} src="assets/keepers.png" alt="Back of Keepers Card" />
      <div className={clsx(classes.holoCardContainer, { [classes.kingImp]: true })}>
        <HoloCard withAnimation card="assets/king-imp.svg" back="/assets/awakened-back.svg" />
      </div>
      <section className={classes.wrapper}>
        <Container className={classes.root}>
          <div className={clsx(classes.suitContainer, { [classes.spacerBottom]: true })}>
            <img src="assets/spade.svg" alt="Spade pip" />
            <Typography className={classes.stepNumber} variant="body1">
              05
            </Typography>
            <img src="assets/diamond.svg" alt="Diamond pip" />
          </div>
          <div className={classes.barSpacer} />
          <Typography className={classes.title} color="primary" variant="h1">
            Enter <strong>the</strong> Metaverse
          </Typography>
          <div className={classes.barSpacer} />
          <div className={classes.explanationContainer}>
            <Typography variant="body1">
              Ultimately, Pips will be a community-owned and driven project with the ability to leverage Pips for other
              projects in the future.
            </Typography>
            <Typography variant="body1">
              The technology created here will enable &quot;card building blocks&quot; to be utilized for other
              applications outside of the Pips Universe.
            </Typography>
          </div>
          <div className={classes.suitContainer}>
            <img src="assets/heart.svg" alt="Heart pip" />
            <img src="assets/club.svg" alt="Club pip" />
          </div>

          <div className={classes.mobileSuitContainer}>
            <img src="assets/spade.svg" alt="Spade pip" />
            <img src="assets/diamond.svg" alt="Diamond pip" />
            <img src="assets/heart.svg" alt="Heart pip" />
            <img src="assets/club.svg" alt="Club pip" />
          </div>
        </Container>
      </section>
    </>
  );
};

export default EnterTheMetaverse;
