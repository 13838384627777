import React, { useEffect, useMemo } from 'react';
import DeckReveal from '../DeckReveal';
import { useAxios } from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setChampionMetadata } from '../../redux/champions';
import { useTypedSelector } from '../../redux/store';
import { useAwakenedKingdomTokenURIs } from '../../hooks/useAwakenedKingdom';

type Props = {
  showCards: boolean;
};

const toCardBackURI = (id: number): string =>
  `https://assets.pipcards.com/ipfs/bafybeicekbtv6pmxdlfod4rbiswawvbonerxlupqu2duo3qmv4aw4u523e/${id}.svg`;

const CardReveal: React.FC<Props> = ({ showCards }) => {
  const tokenURIs = useAwakenedKingdomTokenURIs();
  const dispatch = useDispatch();
  const axios = useAxios();
  const champions = useTypedSelector((state) => state.champions) as any;

  const cards = useMemo(() => {
    return tokenURIs.reduce((acc, token) => {
      if (champions && champions.data[token.id]) {
        return [...acc, { ...champions.data[token.id], id: token.id }];
      }

      return acc;
    }, [] as object[]);
  }, [champions, tokenURIs]);

  useEffect(() => {
    tokenURIs.forEach((item) => {
      const { uri, id } = item;
      if (!uri || id === undefined) return;
      axios
        .get(uri)
        .then((r) => {
          dispatch(setChampionMetadata({ [id as number]: r.data }));
        })
        .catch(() => {
          dispatch(setChampionMetadata({ [id as number]: { image: toCardBackURI(id), requiresGenesisSeed: true } }));
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, dispatch, tokenURIs.length]);
  return <DeckReveal cards={cards} open={showCards} withGrid />;
};

export default CardReveal;
