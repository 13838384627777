import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { CSSProperties } from 'react';

export const sectionWrapper = (theme: Theme): CSSProperties => ({
  padding: theme.spacing(0, 7),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0),
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(7, 7),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(7, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),
      },
    },
    root: {
      marginTop: theme.spacing(10),
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(0.75),
      borderRadius: '3px',
      background: Colors.BackgroundLight,
      [theme.breakpoints.down('md')]: {
        padding: 0,
        background: 'unset',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'unset',
        marginTop: '44px',
      },
    },
    innerContainer: {
      border: `solid 1px ${Colors.Border}`,
      borderRadius: '2px',
      backgroundColor: Colors.Background,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(6, 0, 6, 7.5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 3, 8),
        border: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        background: Colors.BackgroundLight,
        overflow: 'hidden',
      },
    },
    cardBackLargeScreen: {
      position: 'absolute',
      marginTop: theme.spacing(-14),
      width: '750px',
      alignSelf: 'flex-end',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardFrontLargeScreen: {
      width: '840px',
      position: 'absolute',
      alignSelf: 'flex-end',
      padding: theme.spacing(31, 17, 0, 0),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    cardMediumScreenContainer: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(39),
        marginBottom: theme.spacing(8),
        overflowX: 'hidden',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(45),
      },
    },
    cardBackMediumScreen: {
      width: '55%',
      position: 'relative',
      left: '5%',
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        left: '-26%',
        marginTop: theme.spacing(10),
      },
    },
    cardFrontMediumScreen: {
      width: '55%',
      position: 'absolute',
      right: '0',
      top: theme.spacing(-8),
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        right: '-34%',
        top: 'unset',
        marginTop: theme.spacing(4),
      },
    },
    contentContainer: {
      width: '100%',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    blurbContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      zIndex: 3,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      '& h6': {
        marginLeft: theme.spacing(3),
        maxWidth: 350,
        [theme.breakpoints.down('md')]: {
          margin: '0 auto',
          textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: 300,
        }
      },
      '& a': {
        textTransform: 'unset',
        fontFamily: 'Harbour-Light',
        marginTop: theme.spacing(2),
        '&:first-child': {
          margin: theme.spacing(2, 2, 0, 3),
          [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0),
          },
        },
      },
      '& span': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    },
    blurbInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    lineX: {
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(3, 0, 4),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(4, 0, 3),
      },
    },
    logoContainer: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    preTitle: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    title: {
      marginBottom: theme.spacing(7),
      '& strong': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        fontSize: '54px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0),
      },
    },
  }),
);
