import React from 'react';
import Layout from '../../common/Layout';
import AwakenedChampionMintPage from './AwakenenedChampionMintPage';

declare global {
  interface Window {
    ethereum: any;
  }
}

const MintPage: React.FC = () => {
  return (
    <Layout withStrictNetworkConnection withNotifications hideFooter>
      <AwakenedChampionMintPage />
    </Layout>
  );
};

export default MintPage;
