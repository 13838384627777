import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';
import { CARD_RATIO } from '../../../common/HoloCard';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-1px',
      border: `solid 1px ${Colors.Border}`,
      padding: theme.spacing(10, 0, 6),
      background: Colors.BackgroundLight,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: theme.spacing(2),
      },
    },
    stepTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3),
      },
      '& h6': {
        margin: 0,
        lineHeight: 0.8,
      },
      '& p': {
        lineHeight: 0.8,
        paddingLeft: theme.spacing(1),
        margin: theme.spacing(0, 6, 0, 0),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        borderLeft: `solid 4.5px ${theme.palette.primary.main}`,
        [theme.breakpoints.down('md')]: {
          border: 'none',
          margin: 0,
          padding: theme.spacing(1, 0, 5),
        },
      },
    },
    explanationContainer: {
      paddingLeft: theme.spacing(10),
      margin: theme.spacing(0, 0, 8),
      '& p': {
        maxWidth: 560,
        margin: 0,
        color: Colors.Paragraph,
        [theme.breakpoints.down('md')]: {
          margin: '0 auto',
        },
      },
      '& p:first-child': {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 7),
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3),
      },
    },
    cta: {},
    teamTitle: {
      padding: theme.spacing(0, 10, 0),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 7),
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: theme.spacing(0, 3),
      },
    },
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(0, 8),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 4),
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& li': {
        '& em': {
          fontFamily: 'Inter',
        },
        '& img': {
          width: '100%',
          marginBottom: theme.spacing(3),
          borderRadius: '12px',
          backgroundColor: 'white',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          width: '50%',
          marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginBottom: theme.spacing(3),
        },
      },
    },
    faqContainer: {
      padding: theme.spacing(0, 10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 7),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 3),
      },
      '& h6': {
        marginBottom: theme.spacing(4),
      },
    },
    accordionTitle: {
      backgroundColor: Colors.BackgroundLight,
    },
    accordionContent: {
      backgroundColor: Colors.BackgroundLight,
      borderBottom: `solid 1px ${Colors.Border}`,
      '& p': {
        fontFamily: 'unset',
      },
    },
    holoCardContainer: {
      width: 300 * CARD_RATIO,
      height: 300,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: 450 * CARD_RATIO,
        height: 450,
      },
      [theme.breakpoints.down('sm')]: {
        width: 450 * CARD_RATIO,
        height: 450,
      },
      [theme.breakpoints.down('xs')]: {
        width: 300 * CARD_RATIO,
        height: 300,
      },
    },
  }),
);
