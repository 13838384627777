import Artifact from '../artifacts/contracts/AwakenedMintPass.sol/AwakenedMintPass.json';
import { ContractArgs, singleContractMultipleCalls, toInterface, useContract } from './useContract';
import { AwakenedMintPass } from '../typechain';
import { BigNumber, ethers } from 'ethers';
import { safelyUnwrapBNResponse, toSafeAccount } from '../utils';
import contractConfig from '../config';
import { useMemo } from 'react';
import { useCurrentNetworkId } from './useCurrentNetworkId';
import { useContractFunction, useEthers } from '@usedapp/core';

export default function useAwakenedMintPass() {
  return useContract<AwakenedMintPass>('awakenedMintPass', Artifact.abi);
}

const useAwakenedMintPassAddress = () => {
  const currentNetworkChainId = useCurrentNetworkId();
  return contractConfig[currentNetworkChainId].awakenedMintPass || '';
};

export function useAwakenedMintPassContract(): AwakenedMintPass {
  const address = useAwakenedMintPassAddress();

  return useMemo(() => {
    return new ethers.Contract(address, Artifact.abi) as AwakenedMintPass;
  }, [address]);
}

export const useAwakenedMintPassPauseFunc = () => {
  const contract = useAwakenedMintPassContract();
  return useContractFunction(contract, 'pause', { transactionName: 'pause' });
};

export const useAwakenedMintPassUnpauseFunc = () => {
  const contract = useAwakenedMintPassContract();
  return useContractFunction(contract, 'unpause', { transactionName: 'unpause' });
};

export const useAwakenedMintPassWhitelistOnlyFunc = () => {
  const contract = useAwakenedMintPassContract();
  return useContractFunction(contract, 'setWhitelistOnly', { transactionName: 'setWhitelistOnly' });
};

const AwakenedMintPassInterface = toInterface(Artifact.abi);

export const useAwakenedMintPassWithMultipleCalls = singleContractMultipleCalls(
  AwakenedMintPassInterface,
  'awakenedMintPass',
);

type AwakenedMintPassData = {
  owner: string;
  accountBalance: BigNumber;
  maxCount: BigNumber;
  maxCountPerWallet: BigNumber;
  mintFee: BigNumber;
  paused: boolean;
  totalPassesMinted: BigNumber;
  whitelistOnly: boolean;
};

export const useAwakenedMintPassData = (account: string | undefined | null): AwakenedMintPassData => {
  const [owner, totalPassesMinted, whitelistOnly, maxCount, maxCountPerWallet, mintFee, accountBalance, paused] =
    useAwakenedMintPassWithMultipleCalls([
      { method: 'owner' },
      { method: 'totalPassesMinted' },
      { method: 'whitelistOnly' },
      { method: 'maxCount' },
      { method: 'maxCountPerWallet' },
      { method: 'mintFee' },
      { method: 'balanceOf', args: [toSafeAccount(account)] },
      { method: 'paused' },
    ]);

  return {
    owner: owner ? owner[0] : '',
    accountBalance: safelyUnwrapBNResponse(accountBalance),
    whitelistOnly: whitelistOnly?.[0],
    maxCount: safelyUnwrapBNResponse(maxCount),
    maxCountPerWallet: safelyUnwrapBNResponse(maxCountPerWallet),
    mintFee: safelyUnwrapBNResponse(mintFee),
    paused: paused?.[0],
    totalPassesMinted: safelyUnwrapBNResponse(totalPassesMinted),
  };
};

export const useAccountTokenIDs = (): BigNumber[] => {
  const { account } = useEthers();
  const { accountBalance } = useAwakenedMintPassData(account);

  const contractCalls = useMemo(() => {
    let calls: ContractArgs[] = [];
    for (let i = 0; i < accountBalance.toNumber(); i += 1) {
      calls[i] = { method: 'tokenOfOwnerByIndex', args: [account, i] };
    }
    return calls;
  }, [account, accountBalance]);

  return useAwakenedMintPassWithMultipleCalls(contractCalls).map((result) => safelyUnwrapBNResponse(result));
};

export interface NftInfo {
  id: number;
  uri: string;
}

export const useAwakenedMintPassTokenURIs = (): NftInfo[] => {
  const ids = useAccountTokenIDs();
  return ids.map((id) => ({
    id: id.toNumber(),
    uri: id
      ? `https://assets.pipcards.com/ipfs/bafybeicekbtv6pmxdlfod4rbiswawvbonerxlupqu2duo3qmv4aw4u523e/${id}.svg`
      : '',
  }));
};
