import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& h5:first-child': {
        marginBottom: theme.spacing(1),
      },
    },
    button: {
      marginTop: theme.spacing(3),
    },
    discordBlurb: {
      marginBottom: theme.spacing(1),
    },
  }),
);
