type Part = {
  assetPath: string;
  rarity: string;
  score: number;
};

const toSafePartAttributes = (part: any): Part => (part ? part : { assetPath: '', rarity: '', score: 0 });

type ChampionUI = {
  assets: {
    back: string;
    front: string;
    pfp: string;
  };
  colors: {
    skin: {
      primary: string;
      secondary: string;
    };
    suit: string;
    stroke: string;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    back: string;
  };
  formattedValue: string;
  genesisSeed: string;
  kingdom: string;
  parts: {
    arms: {
      assetPath: string;
      rarity: string;
      score: 1;
    };
    belt: {
      assetPath: string;
      rarity: string;
      score: 1;
    };
    body: {
      assetPath: string;
      rarity: string;
      score: 1;
    };
    crown: {
      assetPath: string;
      rarity: string;
      score: 1;
    };
    effect: {
      assetPath: string;
      rarity: string;
      score: 5;
    };
    hair: {
      assetPath: string;
      rarity: string;
      score: 5;
    };
    head: {
      assetPath: string;
      rarity: string;
      score: 1;
    };
    weapon: {
      assetPath: string;
      rarity: string;
      score: number;
    };
  };
  race: string;
  rarityScore: number;
  skills: {
    charisma: number;
    constitution: number;
    dexterity: number;
    intelligence: number;
    strength: number;
    wisdom: number;
  };
  suit: string;
  value: string;
};

export const toChampionData = (metadata: any): ChampionUI => {
  const suit = metadata?.ui?.champion?.suit;
  const isBlackChamp = suit === 'Spades' || suit === 'Clubs';
  const colors = metadata.ui.theme ? (isBlackChamp ? metadata.ui.theme.black : metadata.ui.theme.red) : {};
  const champSuitValue = metadata?.ui
    ? `${(metadata.ui.champion.suit || '').toLowerCase()}-${metadata.ui.champion.value}`
    : '';
  const champStats = (metadata.ui.stats || {})[champSuitValue] || {};
  const { skills, parts, race } = champStats;
  const data = metadata?.ui
    ? {
        ...metadata.ui,
        genesisSeed: metadata.ui.champion.genesisSeed,
        rarityScore: metadata.ui.champion.rarityScore,
        race,
        colors: {
          ...colors,
          skin: {
            primary: colors?.black?.skin?.primary,
            secondary: colors?.black?.skin?.primary,
          },
        },
        parts,
        skills,
      }
    : null;

  return (
    data || {
      image: '',
      genesisSeed: '',
      value: '',
      rarityScore: 0,
      race: '',
      assets: {
        cardBack: '',
        pfp: '',
      },
      colors: {
        skin: {
          primary: '',
          secondary: '',
        },
        primary: '',
        secondary: '',
        tertiary: '',
        quaternary: '',
        stroke: '',
        pfpBack: '',
      },
      skills: {
        strength: '',
        intelligence: '',
        charisma: '',
        dexterity: '',
        constitution: '',
        wisdom: '',
      },
      parts: {
        arms: { ...toSafePartAttributes(metadata?.parts?.arms) },
        belt: { ...toSafePartAttributes(metadata?.parts?.belt) },
        body: { ...toSafePartAttributes(metadata?.parts?.body) },
        crown: { ...toSafePartAttributes(metadata?.parts?.crown) },
        effect: { ...toSafePartAttributes(metadata?.parts?.effect) },
        hair: { ...toSafePartAttributes(metadata?.parts?.hair) },
        head: { ...toSafePartAttributes(metadata?.parts?.head) },
        weapon: { ...toSafePartAttributes(metadata?.parts?.weapon) },
      },
    }
  );
};
