import React from 'react';
import { Colors } from '../../theme/Colors';

type Props = {
  height?: number;
  color?: 'white' | 'primary';
};

const Logo: React.FC<Props> = ({ height, color }) => {
  const fill = color === 'primary' ? Colors.Primary : 'white';
  return (
    <svg width="71" height={height || 25} viewBox="0 0 71 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.74197 23.8878C8.48904 23.2614 7.17016 21.2831 7.17016 18.7112C7.17016 10.9628 19.3368 13.4028 19.3368 6.1819C19.3368 2.95065 16.3034 0.807473 10.4014 0.807473H0.114159C1.36709 1.43394 2.38922 3.57712 2.38922 5.98407V18.7112C2.38922 21.1182 1.46601 23.2614 0.180103 23.8878H9.74197ZM7.17016 5.9511C7.17016 3.31334 8.42309 1.79663 10.5003 1.79663C12.5446 1.79663 13.9294 3.4782 13.9294 6.01704C13.9294 11.0288 9.01659 11.3915 7.17016 15.0514V5.9511ZM29.7614 23.8878C28.4755 23.2614 27.5522 21.1182 27.5522 18.7112V5.98407C27.5522 3.57712 28.5744 1.43394 29.8273 0.807473H20.4962C21.7492 1.43394 22.7713 3.57712 22.7713 5.98407V18.7112C22.7713 21.1182 21.8481 23.2614 20.5622 23.8878H29.7614ZM41.426 23.8878C40.173 23.2614 38.8542 21.2831 38.8542 18.7112C38.8542 10.9628 51.0208 13.4028 51.0208 6.1819C51.0208 2.95065 47.9874 0.807473 42.0854 0.807473H31.7982C33.0511 1.43394 34.0732 3.57712 34.0732 5.98407V18.7112C34.0732 21.1182 33.15 23.2614 31.8641 23.8878H41.426ZM38.8542 5.9511C38.8542 3.31334 40.1071 1.79663 42.1843 1.79663C44.2286 1.79663 45.6134 3.4782 45.6134 6.01704C45.6134 11.0288 40.7006 11.3915 38.8542 15.0514V5.9511ZM61.6432 24.1187C65.435 24.1187 70.9743 22.2392 70.9743 16.5351C70.9743 8.62183 57.0931 10.765 57.0931 4.86303C57.0931 3.28037 58.5438 1.69772 61.1486 1.69772C63.6545 1.69772 65.0393 3.11551 65.0393 4.63222C65.0393 5.32463 64.7755 6.01704 64.215 6.61054C64.7755 7.23701 65.7647 7.73159 66.8198 7.73159C68.5014 7.73159 69.5235 6.57757 69.5235 5.15977C69.5235 3.11551 67.2155 0.57667 61.3794 0.57667C57.2579 0.57667 53.1035 2.85174 53.1035 7.46781C53.1035 14.9854 66.523 13.0071 66.523 19.3707C66.523 21.6128 64.8085 22.9976 62.4015 22.9976C58.313 22.9976 56.1039 19.3047 56.1039 16.5351C56.1039 16.1065 56.1369 15.7108 56.2688 15.3481H56.005C54.0926 15.3481 52.3451 16.5681 52.3451 18.7442C52.3451 21.8436 56.2358 24.1187 61.6432 24.1187Z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
