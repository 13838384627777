import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { sectionWrapper } from '../Hero/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...sectionWrapper(theme),
    },
    root: {
      background: Colors.BackgroundLight,
      display: 'flex',
      padding: 0,
      border: `solid 1px ${Colors.Border}`,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
    },
    leftContainer: {
      padding: theme.spacing(10, 3, 6, 0),
      width: '50%',
      borderRight: `solid 1px ${Colors.Border}`,
      '& h6': {
        marginBottom: 0,
        fontSize: '18px',
      },
      '& p': {
        marginBottom: theme.spacing(2),
        color: Colors.Paragraph,
      },
      [theme.breakpoints.down('md')]: {
        border: `solid 1px ${Colors.Border}`,
        borderRadius: '3px',
        width: '100%',
        padding: theme.spacing(3, 7),
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
    },
    leftInnerContainer: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '60%',
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
      },
    },
    timelineWrapper: {
      '& h5': {
        position: 'absolute',
        transform: 'translate(-60%, 150px) rotate(270deg)',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      margin: theme.spacing(6, 3, 3, 10),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(4, 3, 3, 3),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 3, 3, 3),
      },
    },
    timelineContinuedContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      marginTop: '-12px',
      marginLeft: '-7.5px',
      '& h6': {
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
          marginLeft: '18px',
        },
      },
    },
    stepTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      '& h6': {
        margin: 0,
        lineHeight: 0.8,
      },
      '& p': {
        lineHeight: 0.8,
        paddingLeft: theme.spacing(1),
        margin: theme.spacing(0, 6, 0, 0),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        borderLeft: `solid 4.5px ${theme.palette.primary.main}`,
        [theme.breakpoints.down('md')]: {
          borderTop: `solid 4.5px ${theme.palette.primary.main}`,
          borderLeft: 'none',
          padding: theme.spacing(1, 0, 5),
        },
        [theme.breakpoints.down('sm')]: {
          border: 'none',
          padding: theme.spacing(1, 0, 3),
        },
      },
    },
    tabletStepTitleContainer: {
      display: 'none',
      textAlign: 'center',
      margin: theme.spacing(6, 0),
      '& h6': {
        margin: 0,
        lineHeight: 0.8,
      },
      '& p': {
        lineHeight: 0.8,
        paddingLeft: theme.spacing(1),
        margin: theme.spacing(0),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        borderLeft: `solid 4.5px ${theme.palette.primary.main}`,
        [theme.breakpoints.down('md')]: {
          borderLeft: 'none',
          padding: theme.spacing(1, 0, 3),
        },
        [theme.breakpoints.down('sm')]: {
          border: 'none',
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'unset',
      },
    },
    cardContainer: {
      padding: theme.spacing(0, 3),
      margin: theme.spacing(8, 0),
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'nowrap',
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: theme.spacing(3, 0),
      },
      '& li': {
        width: '50%',
        '& img': {
          width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 0,
        textAlign: 'center',
        padding: theme.spacing(2),
        '& h6': {
          color: Colors.Paragraph,
        },
        '& hgroup': {
          position: 'relative',
          marginTop: '-32px',
          [theme.breakpoints.down('md')]: {
            marginTop: 0,
            position: 'unset',
          },
        },
        [theme.breakpoints.down('md')]: {
          width: '33%',
          marginLeft: '-96px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '75%',
          margin: theme.spacing(6, 0, 0),
        },
      },
      '& li:nth-child(1)': {
        padding: theme.spacing(0, 6, 6, 0),
        borderRight: `solid 1px ${Colors.Border}`,
        borderBottom: `solid 1px ${Colors.Border}`,
        [theme.breakpoints.down('md')]: {
          border: 'none',
          padding: 0,
          marginLeft: 0,
        },
      },
      '& li:nth-child(2)': {
        padding: theme.spacing(0, 0, 6, 6),
        borderBottom: `solid 1px ${Colors.Border}`,
        [theme.breakpoints.down('md')]: {
          border: 'none',
          padding: 0,
        },
      },
      '& li:nth-child(3)': {
        padding: theme.spacing(6, 6, 6, 0),
        borderRight: `solid 1px ${Colors.Border}`,
        [theme.breakpoints.down('md')]: {
          border: 'none',
          padding: 0,
        },
      },
      '& li:nth-child(4)': {
        padding: theme.spacing(6, 0, 6, 6),
        [theme.breakpoints.down('md')]: {
          border: 'none',
          padding: 0,
        },
      },
    },
    pipsLogoContainer: {
      position: 'relative',
      top: '-50%',
      marginTop: '-52px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'cetner',
      '& img': {
        backgroundColor: Colors.BackgroundDark,
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    awakened: {
      color: Colors.Awakened,
    },
    hunters: {
      color: Colors.Hunters,
    },
    mystics: {
      color: Colors.Mystics,
    },
    keepers: {
      color: Colors.Keepers,
    },
    explanationContainer: {
      paddingLeft: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(6, 9, 0),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 3, 0),
      },
    },
    paragraphEmphasis: {
      fontSize: '22px',
    },
    rightContainer: {
      backgroundColor: Colors.BackgroundDark,
      position: 'relative',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: theme.spacing(6, 0, 4),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);
