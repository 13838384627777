import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import StyledLink from '../../../../common/StyledLink';
import LineX from '../../../../common/LineX';

type Props = {
  maxMintCountReached: boolean;
  isSuccessMessage: boolean;
  isPaused: boolean;
  isLoading: boolean;
  isSoldOut: boolean;
  mintCount: number;
  isNotWhitelisted: boolean;
};

const DiscordCTA: React.FC = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.discordBlurb} variant="body2">
      Learn more in our <StyledLink title="Discord" target="_blank" href="https://discord.com/invite/8Q6d3Hh6rh" />.
    </Typography>
  );
};

const PageTitle: React.FC<Props> = ({
  isPaused,
  isLoading,
  maxMintCountReached,
  mintCount,
  isSuccessMessage,
  isSoldOut,
  isNotWhitelisted,
}) => {
  const classes = useStyles();

  if (isNotWhitelisted) {
    return (
      <Typography variant="h5">
        Minting is currently only available for whitelisted accounts. Please come back later.
      </Typography>
    );
  }

  if (isSoldOut) {
    return (
      <div className={classes.root}>
        <LineX horizontal marginBottom="small" color="gray" />
        {isSuccessMessage && (
          <Typography variant="h5">
            Success! You have {mintCount} deck{mintCount > 1 ? 's' : ''}
          </Typography>
        )}
        <Typography variant="h5">Sold out</Typography>
        <Button size="large" color="primary" href="https://moonbeans.io" target="_blank" variant="contained">
          Buy on Marketplace
        </Button>
      </div>
    );
  }

  if (isPaused) {
    return (
      <div className={classes.root}>
        <LineX horizontal marginBottom="small" color="gray" />
        <Typography variant="h5">The Tomb is Sealed</Typography>
        <DiscordCTA />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.root}>
        <LineX horizontal marginBottom="small" color="gray" />
        <Typography variant="h5">Loading...</Typography>
      </div>
    );
  }

  if (mintCount === 0) {
    return (
      <div className={classes.root}>
        <LineX horizontal marginBottom="small" color="gray" />
        <Typography variant="h5">Welcome to the Tomb</Typography>
      </div>
    );
  }

  if (isSuccessMessage) {
    return (
      <div className={classes.root}>
        <Typography variant="h5">
          Success! You have {mintCount} deck{mintCount > 1 ? 's' : ''}
        </Typography>
        <DiscordCTA />
      </div>
    );
  }

  if (maxMintCountReached) {
    return (
      <div className={classes.root}>
        <Typography variant="h5">You have minted all {mintCount} decks</Typography>
        <DiscordCTA />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        You have {mintCount} deck{mintCount > 1 ? 's' : ''}
      </Typography>
      <Typography variant="h5">Welcome back to the Tomb</Typography>
    </div>
  );
};

export default PageTitle;
