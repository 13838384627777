import React, { useCallback, useEffect, useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import Layout from '../../common/Layout';
import ConnectWalletButton from '../../common/Account/ConnectWalletButton';
import CardReveal from '../../common/CardReveal';
import { useAwakenedKingdomData } from '../../hooks/useAwakenedKingdom';

const VaultPage: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const [openDeck, setOpenDeck] = useState(false);
  const { accountBalance } = useAwakenedKingdomData(account);

  const cardCount = accountBalance.toNumber();

  const setOpenDeckCallback = useCallback(() => {
    setTimeout(() => {
      setOpenDeck(cardCount > 0);
    }, 500);
  }, [setOpenDeck, cardCount]);

  useEffect(() => setOpenDeckCallback(), [setOpenDeckCallback]);

  if (!account) {
    return (
      <Layout hideFooter>
        <Container maxWidth="lg" className={classes.root}>
          <Typography variant="h5">Your Vault</Typography>
          <div className={classes.connectButtonContainer}>
            <ConnectWalletButton size="large" />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout withStrictNetworkConnection withNotifications hideFooter>
      <Container maxWidth="lg" className={classes.root}>
        <Typography variant="h4">Your Vault</Typography>
        <Typography className={classes.sectionTitle} variant="h5">
          Decks
        </Typography>
        <Typography className={classes.sectionSubtitle} variant="subtitle1">
          (Click on Cards)
        </Typography>
        {cardCount > 0 ? (
          <CardReveal showCards={openDeck} />
        ) : (
          <Typography variant="h5" color="primary">
            You have no decks.
          </Typography>
        )}
      </Container>
    </Layout>
  );
};

export default VaultPage;
