import React from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useEthers } from '@usedapp/core';
import Layout from '../../common/Layout';
import ConnectWalletButton from '../../common/Account/ConnectWalletButton';
import { isPendingOrMining } from '../../utils';
import {
  useAwakenedMintPassData,
  useAwakenedMintPassPauseFunc,
  useAwakenedMintPassUnpauseFunc,
  useAwakenedMintPassWhitelistOnlyFunc,
} from '../../hooks/useAwakenedMintPass';
import { formatEther } from 'ethers/lib/utils';

const DappStatePage: React.FC = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const pauseFunc = useAwakenedMintPassPauseFunc();
  const unpauseFunc = useAwakenedMintPassUnpauseFunc();
  const setWhitelistFunc = useAwakenedMintPassWhitelistOnlyFunc();
  const { owner, paused, whitelistOnly, totalPassesMinted, maxCount, maxCountPerWallet, mintFee } =
    useAwakenedMintPassData(account);
  const isOwner = account && account === owner;

  if (!account) {
    return (
      <Layout hideFooter>
        <Container maxWidth="lg" className={classes.root}>
          <div className={classes.noAccountContainer}>
            <Typography variant="h4" color="primary">
              Awakened Mint Pass State
            </Typography>
            <ConnectWalletButton size="large" />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout withNotifications hideFooter>
      <Container maxWidth="lg" className={classes.root}>
        <Typography variant="h4" color="primary">
          Awakened Mint Pass State
        </Typography>

        <ul className={classes.balanceList}>
          <li>
            <Typography variant="h5" color="primary">
              Owner
            </Typography>
            <div className={classes.valueContainer}>{owner}</div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Minted
            </Typography>
            <div className={classes.valueContainer}>
              {totalPassesMinted.toString()}/{maxCount.toString()}
            </div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Mint Fee
            </Typography>
            {mintFee ? `${parseFloat(formatEther(mintFee)).toPrecision(3)} MOVR` : '--'}
          </li>
          <li>
            <Typography variant="h5" color="primary">
              Max Mint Count Per Wallet
            </Typography>
            <div className={classes.valueContainer}>{maxCountPerWallet.toString()}</div>
          </li>
          <li>
            <Typography variant="h5" color="primary">
              {paused ? 'Paused' : 'Unpaused'}
            </Typography>
            <div className={classes.valueContainer}>
              <Button
                size="large"
                disableElevation
                disabled={isPendingOrMining(pauseFunc.state.status) || !isOwner}
                variant="contained"
                color="primary"
                onClick={() => (paused ? unpauseFunc.send() : pauseFunc.send())}
              >
                {paused ? 'Unpause' : 'Pause'}
              </Button>
            </div>
          </li>

          <li>
            <Typography variant="h5" color="primary">
              {whitelistOnly ? 'Whitelist Only' : 'Public Sale Enabled'}
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="body1">{whitelistOnly}</Typography>
              <Button
                size="large"
                disableElevation
                disabled={isPendingOrMining(setWhitelistFunc.state.status) || !isOwner}
                variant="contained"
                color="primary"
                onClick={() => setWhitelistFunc.send(!whitelistOnly)}
              >
                {whitelistOnly ? 'Turn off Whitelist' : 'Set to Whitelist Only'}
              </Button>
            </div>
          </li>
        </ul>

        {unpauseFunc.state.errorMessage && (
          <Typography variant="body1" color="primary">
            {unpauseFunc.state.errorMessage}
          </Typography>
        )}

        {setWhitelistFunc.state.errorMessage && (
          <Typography variant="body1" color="primary">
            {setWhitelistFunc.state.errorMessage}
          </Typography>
        )}

        {pauseFunc.state.errorMessage && (
          <Typography variant="body1" color="primary">
            {pauseFunc.state.errorMessage}
          </Typography>
        )}
      </Container>
    </Layout>
  );
};

export default DappStatePage;
