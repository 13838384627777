import Artifact from '../artifacts/contracts/AwakenedKingdom.sol/AwakenedKingdom.json';
import { ContractArgs, singleContractMultipleCalls, toInterface, useContract } from './useContract';
import { AwakenedKingdom } from '../typechain';
import { BigNumber, ethers } from 'ethers';
import { safelyUnwrapBNResponse, toFastURI, toSafeAccount } from '../utils';
import contractConfig from '../config';
import { useMemo } from 'react';
import { useCurrentNetworkId } from './useCurrentNetworkId';
import { useContractCall, useContractFunction, useEthers } from '@usedapp/core';
import { NftInfo } from './useAwakenedMintPass';

export default function useAwakenedKingdom() {
  return useContract<AwakenedKingdom>('awakenedKingdom', Artifact.abi);
}

const useAwakenedKingdomAddress = (): string => {
  const currentNetworkChainId = useCurrentNetworkId();
  return contractConfig[currentNetworkChainId].awakenedKingdom || '';
};

export function useAwakenedKingdomContract(): AwakenedKingdom {
  const currentNetworkChainId = useCurrentNetworkId();
  const address = contractConfig[currentNetworkChainId].awakenedKingdom || '';

  return useMemo(() => {
    return new ethers.Contract(address, Artifact.abi) as AwakenedKingdom;
  }, [address]);
}

export const useAwakenedKingdomMintFunc = () => {
  const contract = useAwakenedKingdomContract();
  return useContractFunction(contract, 'mintDeck', { transactionName: 'mintDeck' });
};

const AwakenedKingdomInterface = toInterface(Artifact.abi);

export const useAwakenedKingdomWithMultipleCalls = singleContractMultipleCalls(
  AwakenedKingdomInterface,
  'awakenedKingdom',
);

type AwakenedKingdomData = {
  owner: string;
  accountBalance: BigNumber;
  maxCount: BigNumber;
  maxCountPerWallet: BigNumber;
  mintFee: BigNumber;
  paused: boolean;
  totalSupply: BigNumber;
  isInitialized: boolean;
};

export const useAwakenedKingdomData = (account: string | undefined | null): AwakenedKingdomData => {
  const [owner, totalSupply, maxCount, maxCountPerWallet, mintFee, accountBalance, paused, isInitialized] =
    useAwakenedKingdomWithMultipleCalls([
      { method: 'owner' },
      { method: 'totalSupply' },
      { method: 'maxCount' },
      { method: 'maxCountPerWallet' },
      { method: 'mintFee' },
      { method: 'balanceOf', args: [toSafeAccount(account)] },
      { method: 'paused' },
      { method: 'isInitialized' },
    ]);

  return {
    owner: owner ? owner[0] : '',
    accountBalance: safelyUnwrapBNResponse(accountBalance),
    maxCount: safelyUnwrapBNResponse(maxCount),
    maxCountPerWallet: safelyUnwrapBNResponse(maxCountPerWallet),
    mintFee: safelyUnwrapBNResponse(mintFee),
    paused: paused?.[0],
    totalSupply: safelyUnwrapBNResponse(totalSupply),
    isInitialized: isInitialized?.[0],
  };
};

export const useAccountTokenIDs = (): BigNumber[] => {
  const { account } = useEthers();
  const { accountBalance } = useAwakenedKingdomData(account);

  const contractCalls = useMemo(() => {
    const calls: ContractArgs[] = [];
    for (let i = 0; i < accountBalance.toNumber(); i += 1) {
      calls.push({ method: 'tokenOfOwnerByIndex', args: [account, i] });
    }
    return calls;
  }, [account, accountBalance]);

  return useAwakenedKingdomWithMultipleCalls(contractCalls)
    .map((result) => {
      return safelyUnwrapBNResponse(result);
    })
    .filter((i) => i.gt(0));
};

export const useAwakenedKingdomOwnerOf = (id: number): string => {
  const address = useAwakenedKingdomAddress();
  const nftOwnerAddress = useContractCall({
    abi: AwakenedKingdomInterface,
    address,
    method: 'ownerOf',
    args: [id],
  });

  return nftOwnerAddress ? nftOwnerAddress[0] : ethers.constants.AddressZero;
};

export const useAwakenedKingdomTokenURI = (id: number): string => {
  const address = useAwakenedKingdomAddress();
  const uri = useContractCall({
    abi: AwakenedKingdomInterface,
    address,
    method: 'tokenURI',
    args: [id],
  });

  return uri ? toFastURI(uri[0]) : '';
};

export const useAwakenedKingdomTokenURIs = (): NftInfo[] => {
  const ids = useAccountTokenIDs();
  const contractCalls = useMemo(
    () =>
      ids.map((id) => {
        return {
          method: 'tokenURI',
          args: [id],
        };
      }),
    [ids],
  );

  return useAwakenedKingdomWithMultipleCalls(contractCalls)
    .map((result, i) => ({ id: ids[i].toNumber(), uri: result ? toFastURI(result[0]) : '' }))
    .filter((i) => i.uri);
};

export const useAwakenedKingdomCohortLength = (cohortId: number): number => {
  const address = useAwakenedKingdomAddress();
  const uri = useContractCall({
    abi: AwakenedKingdomInterface,
    address,
    method: 'cohortLength',
    args: [cohortId],
  });

  return safelyUnwrapBNResponse(uri).toNumber();
};

export const useAwakenedKingdomsBalance = (account: string | undefined | null): string => {
  const address = useAwakenedKingdomAddress();

  const balance = useContractCall({
    abi: AwakenedKingdomInterface,
    address,
    method: 'balanceOf',
    args: [account],
  });

  return balance ? balance[0] : '';
};

export const useAwakenedKingdomCohortIds = (cohortId: number): number[] => {
  const cohortLength = useAwakenedKingdomCohortLength(cohortId);
  const contractCalls = useMemo(() => {
    const calls = [];
    for (let id = 0; id < cohortLength; id++) {
      calls.push({
        method: 'cohortId',
        args: [cohortId, id],
      });
    }
    return calls;
  }, [cohortId, cohortLength]);

  const results = useAwakenedKingdomWithMultipleCalls(contractCalls);

  return useMemo(() => results.map((result) => result?.[0] || 0), [results]);
};

type GenesisSeedData = {
  id: number;
  genesisSeed: string;
};

export const useAwakenedKingdomCohortGenesisSeeds = (cohortId: number): GenesisSeedData[] => {
  const ids = useAwakenedKingdomCohortIds(cohortId);
  const contractCalls = useMemo(
    () =>
      ids.map((id) => ({
        method: 'genesisSeeds(uint256)',
        args: [id],
      })),
    [ids],
  );

  const data = useAwakenedKingdomWithMultipleCalls(contractCalls);

  return useMemo(
    () =>
      data.map((result, i) => ({
        id: BigNumber.from(ids?.[i] || 0).toNumber(),
        genesisSeed: result ? result[0] : 'not-found',
      })),
    [data, ids],
  );
};
