import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import { CARD_RATIO } from '../../../common/HoloCard';

const MARGIN_TOP = 6;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
      marginTop: theme.spacing(MARGIN_TOP),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftContainerWrapper: {},
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `calc(100vh - ${theme.spacing(MARGIN_TOP)})px`,
      backgroundColor: Colors.BackgroundDark,
      backgroundImage: "url('assets/door-faded.png')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundOrigin: 'content-box',
      backgroundSize: 'contain',
      width: '45%',
      padding: theme.spacing(6),
      '& img': {
        width: '50%',
        [theme.breakpoints.down('md')]: {
          width: '75%',
        },
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        backgroundSize: 'cover',
      },
    },
    rightContainer: {
      width: '55%',
      height: `calc(100vh - ${theme.spacing(MARGIN_TOP)}px)`,
      [theme.breakpoints.down('sm')]: {
        height: 'unset',
        width: '100%',
      },
      '& h5': {
        marginBottom: theme.spacing(2),
      },
    },
    rightScrollContainer: {
      padding: theme.spacing(8, 7),
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      overflowY: 'scroll',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(8, 4),
      },
      [theme.breakpoints.down('sm')]: {
        overflowY: 'unset',
        padding: theme.spacing(0),
      },
    },
    link: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.light,
      },
    },
    deckContainer: {
      position: 'relative',
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    rightInnerContainer: {
      maxWidth: 450,
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
      },
    },
    titleSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& h5': {
        marginBottom: theme.spacing(1),
      },
    },
    faqContainer: {
      textAlign: 'left',
      listStyleType: 'none',
      padding: 0,
      margin: theme.spacing(3, 'auto'),
      maxWidth: 346,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(6),
      },
      '& li': {
        padding: 0,
        margin: theme.spacing(0, 0, 2),
      },
      '& a': {
        color: 'white',
        textDecoration: 'underline',
        '&:hover': {
          color: 'white',
        },
      },
    },
    form: {
      maxWidth: 400,
      margin: theme.spacing(3, 'auto', 0),
    },
    button: {
      margin: theme.spacing(0, 0, 2),
      padding: theme.spacing(1.5, 2),
      color: Colors.BackgroundDark,
    },
    mintCountExplanation: {
      maxWidth: 400,
      margin: theme.spacing(2, 0, 1),
    },
    mintExplanation: {
      margin: theme.spacing(3, 0, 2),
    },
    resultMessage: {
      marginTop: theme.spacing(2),
    },
    maxCountReachedTitle: {
      marginTop: theme.spacing(3),
    },
    totalMinted: {
      margin: theme.spacing(3, 0),
    },
    holoCardContainer: {
      width: 400 * CARD_RATIO,
      height: 400,
      [theme.breakpoints.down('sm')]: {
        width: 350 * CARD_RATIO,
        height: 350,
      },
    },
  }),
);
