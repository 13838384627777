import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontFamily: 'Harbour-light',
      color: Colors.BackgroundDark,
      fontWeight: theme.typography.fontWeightRegular,
    },
    withPadding: {
      padding: theme.spacing(1.5, 4),
    },
    marginBottomLarge: {
      marginBottom: theme.spacing(3),
    },
  }),
);
